import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { urlConfig } from '../service/urlConfig.service';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-vendeur',
  templateUrl: './vendeur.component.html',
  styleUrls: ['./vendeur.component.scss']
})
export class VendeurComponent implements OnInit {
  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });

  @ViewChild('secondDialog') secondDialog: TemplateRef<any>;
  @ViewChild('op') overlayPanel: OverlayPanel;
  @ViewChild('opLogin') overlayPanelLogin: OverlayPanel;
  @ViewChild('opMail') overlayPanelMail: OverlayPanel;

  contrat = { siteWeb: false, animationClientele: false, communication: false };

  lstVendeur : any;
  seller: { Login: string, Nom: string, Prenom: string, Mail: string, Password: string } = { Login: "", Nom: "", Prenom: "", Mail: "", Password: ""};
  msgErreurLogin: any;
  msgErreurMail: any;
  msgErreurPsw: any;

  enseigneSelected: any;

  isValidLogin: boolean = false;
  isValidEmail: boolean = false;
  hasLowercase = false;
  hasUppercase = false;
  hasSpecialChar = false;
  hasDigit = false;
  hasMinLength = false;

  constructor(private router: Router, private http: HttpClient, private app: AppComponent, private messageService: MessageService) {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {

    this.app.ensSelected.subscribe((ens: any) => {
      this.enseigneSelected = ens;

      this.enseigneClick(ens);
    });
  }

  chargementVendeur() {
    this.http.post(urlConfig.getUrl("getVendeur"), this.enseigneSelected).subscribe((res: any) => {
      this.lstVendeur = res.data;
    });

  }

  enseigneClick(ens) {

    this.enseigneSelected = ens;

    this.contrat = {
      siteWeb: this.enseigneSelected?.IdContratsNavigation?.SiteWeb,
      animationClientele: this.enseigneSelected?.IdContratsNavigation?.AnimationClientele,
      communication: this.enseigneSelected?.IdContratsNavigation?.Communication
    };

    this.chargementVendeur();
  }

  saveSeller() {

    this.http.post(urlConfig.getUrl("setVendeur"), { user: this.seller, enseigne: this.enseigneSelected }).subscribe((res: any) => {
      this.msgErreurLogin = null;
      this.msgErreurMail = null;
      this.msgErreurPsw = null;

      if (res.success) {
        this.chargementVendeur();
        this.seller = { Login: "", Nom: "", Prenom: "", Mail: "", Password: "" };
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'L\'enregistrement du vendeur a été effectué avec succès ' });

      }
      else {
        if (res.message.includes("User name")) {
          this.msgErreurLogin = "Login déjà utilisé, merci d'en choisir un autre.";
        }

        if (res.message.includes("Email")) {
          this.msgErreurMail = "Adresse mail déjà associé à un compte.";
        }

        if (res.message.includes("Passwords")) {
          this.msgErreurPsw = "Le mot de passe doit contenir au moins une lettre minuscule, une majuscule, un caractère spéciale, un chiffre et doit être d'une longueur minimale de 8 caractères.";
        }
      }
    });
  }

  onRowEditInit(vendeur: any) {
    
  }

  onRowEditSave(vendeur: any) {
    // Ici, vous pouvez appeler un service pour sauvegarder l'élément édité.
  }

  onRowEditCancel(vendeur: any, index: number) {
    this.chargementVendeur();
  }

  onRowDelete(vendeur) {
    this.http.post(urlConfig.getUrl("deleteseller"), vendeur).subscribe((res: any) => {
      this.chargementVendeur();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Compte vendeur supprimé avec succès.' });
    });
  }

  onPasswordInput() {
    const password = this.seller.Password;
    this.hasLowercase = /[a-z]/.test(password);
    this.hasUppercase = /[A-Z]/.test(password);
    this.hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
    this.hasDigit = /\d/.test(password);
    this.hasMinLength = password.length >= 8;
  }

  onPasswordFocus(event: any): void {
    this.overlayPanel.toggle(event);
  }

  onLoginInput(event:any) {
    this.isValidLogin = this.seller.Login && this.seller.Login.length >= 3;
  }

  onLoginFocus(event: any) {
    this.overlayPanelLogin.toggle(event);
  }

  onEmailInput() {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    this.isValidEmail = emailPattern.test(this.seller.Mail);
    
  }

  onEmailFocus(event: any) {
    this.overlayPanelMail.toggle(event);
  }

  isValidForm(): boolean {
    return this.isValidLogin && this.hasLowercase && this.hasUppercase && this.hasSpecialChar && this.hasDigit && this.hasMinLength && this.isValidEmail;
  }

}
