import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
// import plugin

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Flytagger';

  ensSelected: BehaviorSubject<any> = new BehaviorSubject<any>({});
  reloadEnseigne: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    
  }

}
