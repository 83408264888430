<div class="globalContainer">

  <div class="homeContainer2">

    <!--<h1 style="margin-left:20px; padding-top:20px;"><span style="color:#019FC2">CR&#201;EZ</span> VOTRE PUB</h1>-->
    <!--<div style="width:100%; text-align:center">
      <img src="../../assets/images/cloche.png" style="position: absolute;top: 5em;width: 130px;" />
    </div>-->

    <mat-tab-group dynamicHeight>

      <!--envoie rapide-->
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">send</mat-icon>
          Envoie rapide
        </ng-template>

        <div class="mat-elevation-z4">


          <div class="contenue">

            <div style="margin-top:10px;">
              <form style="margin-left:20px;">
                <div class="form-group">
                  <label for="exampleFormControlFile1">Insérer une image : </label>
                  <div style="display:flex; width:100%">
                    <button type="button" class="btn btn-light" style="height: 35px;" (click)="fileInput.click()">
                      <span>Choisir un fichier</span>
                      <input #fileInput type="file" (change)="onFileChange($event)" style="display:none;" />
                    </button>
                    <span style="margin-left: 10px; margin-right: 10px; margin-top: 5px;">{{imageData ? nameFile : 'aucun fichier sélectionné'}}</span>
                    <button class="btn" (click)="clearFile()" *ngIf="imageData" style="border:none; color:white;"><i class="fa fa-times-circle"></i></button>
                  </div>
                </div>
              </form>



              <div class="input-group" style="margin:20px;">
                <span style="width:100%" class="input-group-addon" id="basic-addon1">Titre de la pub : </span>
                <textarea style="background-color:#242424; color:white;resize: none;" cols="40" rows="1" placeholder="Entrer le texte ici" [(ngModel)]="titre"></textarea>
              </div>

              <div class="input-group" style="margin:20px;">
                <span style="width:100%" class="input-group-addon" id="basic-addon1">Corp de la pub : </span>
                <textarea style="background-color:#242424; color:white; resize: none;" cols="40" rows="6" placeholder="Entrer le texte ici" [(ngModel)]="textPub"></textarea>
              </div>


              <!--<div>

                <div class="form-group" style="margin:20px;">
                  <label for="formControlRange">Distance de publication : </label>
                  <label slot="end" style="width:100px; margin-left : 20px;">{{range < 1000 ? range : range/ 1000}} {{range < 1000 ? 'm' : 'km'}}</label>
                  <div>
                    <div style="display:flex" class="custom-slider">
                      <ng5-slider [(value)]="range" [options]="options" (valueChange)="newRange();"></ng5-slider>
                    </div>
                    <div style="text-align:center;">

                    </div>
                  </div>
                </div>
              </div>-->

            </div>

            <div id="map" style="width: 90%; position:relative; margin-left: 10px; margin-right: 10px; color:black; height: 30rem;">
            </div>

            <div style="height: 30rem;">
              <!--<img style="width: 100%;height: 100%;" src="../../assets/images/iphoneXS.png" />-->
              <div class="apercuIphone">
                <div *ngIf="imageData" style="height: 60%; width: 90%">
                  <img [src]="imageData" style="width:100%; height: 100%; border-top-left-radius: 28px; border-top-right-radius: 28px;" />
                </div>
                <div style="overflow:hidden; height: 2em; margin-bottom: 7px; width: 87%;" *ngIf="titre">
                  <h4>{{titre}}</h4>
                </div>
                <div style="height:29%; margin-bottom:0px; width: 89%;" *ngIf="textPub">
                  <p style="width:100%;word-wrap:break-word;height: 100%;overflow: hidden;">{{textPub}}</p>
                </div>
              </div>
            </div>

          </div>

          <div style="padding-bottom: 10px; text-align: right; margin-top: 10px;">
            <button type="button" class="btn btn-light" (click)="onSubmit()" style="margin-right: 5%;margin-top: 5%;">Enregistrer</button>
          </div>


        </div>
      </mat-tab>

      <!--Campagne-->
      <!--<mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon">store</mat-icon>
          Campagne
        </ng-template>

        <div class="mat-elevation-z4" style="height: 100%;">


          <mat-horizontal-stepper [linear]="true" #stepper style="height: 100%;">-->
      <!--nom-->
      <!--<mat-step [stepControl]="firstFormGroup" class="nom">
        <form [formGroup]="firstFormGroup" class="FormNom">
          <ng-template matStepLabel>Nom de la campagne</ng-template>
          <mat-form-field>
            <mat-label>Nom de la campagne</mat-label>
            <input matInput placeholder="Nom de la campagne" formControlName="nomCtrl" required>
          </mat-form-field>
          <div>
            <button mat-raised-button color="primary" matStepperNext (click)="campagneMap()">Suivant</button>
          </div>
        </form>
      </mat-step>-->
      <!--coordonnées-->
      <!--<mat-step [stepControl]="secondFormGroup" label="Loclisation" class="coordonnees">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Address</ng-template>
          <div style="display:flex; flex-direction: row">
            <div id="mapCampagne" style="width: 75%; position:relative; margin-left: 10px; margin-right: 10px; color:black; height: 24rem;"></div>

            <div class="form-group" style="margin:20px;">
              <div style="display: flex">
                <label for="formControlRange">Distance de publication : </label>
                <label slot="end" style="width:100px; margin-left : 20px;">{{rangeCampagne < 1000 ? rangeCampagne : rangeCampagne/ 1000}} {{rangeCampagne < 1000 ? 'm' : 'km'}}</label>
              </div>

              <div>
                <div style="display:flex" class="custom-slider">
                  <ng5-slider [(value)]="rangeCampagne" [options]="optionsCampagne" (valueChange)="newRangeCampagne();"></ng5-slider>
                </div>
                <div style="text-align:center;">

                </div>
              </div>
            </div>
          </div>

          <input matInput formControlName="secondCtrl" placeholder="Ex. 1 Main St, New York, NY" hidden>

          <div style="display: flex;flex-direction: row;justify-content: flex-end;">
            <button mat-raised-button matStepperPrevious style="margin-right: 10px;">Retour</button>
            <button mat-raised-button color="primary" matStepperNext (click)="saveAdresse()">Suivant</button>
          </div>
        </form>
      </mat-step>-->
      <!--contenue-->
      <!--<mat-step [stepControl]="thirdFormGroup" class="contenue">
        <form [formGroup]="thirdFormGroup">
          <ng-template matStepLabel>Contenue</ng-template>
          <div style="display: flex; flex-direction: row; justify-content: space-evenly;">

            <div style="display: flex; flex-direction: column; justify-content: space-between;">

              <mat-form-field style="width:430px">
                <mat-label>Titre</mat-label>
                <input matInput placeholder="Titre" formControlName="titleCtrl" required>
              </mat-form-field>
              <mat-form-field style="width:430px">
                <mat-label>Texte</mat-label>
                <textarea matInput matAutosizeMinRows="10" placeholder="Texte" formControlName="bodyCtrl" required></textarea>
              </mat-form-field>
              <mat-form-field style="width:430px">
                <mat-label>Image</mat-label>
                <div class="form-group">
                  <input formControlName="file"
                         id="file"
                         type="file"
                         class="form-control"
                         (change)="onFileChangeCampagne($event)" style="margin-top: 25px;" />
                  <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                    <div *ngIf="f.file.errors.required">File is required.</div>
                  </div>
                </div>-->
      <!--<div class="form-group">
        <div style="display:flex; width:100%">
          <button type="button" class="btn btn-light" style="height: 35px;" (click)="fileInput.click()">
            <span>Choisir un fichier</span>
            <input #fileInput type="file" (change)="onFileChangeCampagne($event)" style="display:none;" />
          </button>
          <span style="margin-left: 10px; margin-right: 10px; margin-top: 5px; overflow: hidden;">{{imageDataCampagne ? nameFileCampagne : 'aucun fichier sélectionné'}}</span>
          <button class="btn" (click)="clearFileCampagne()" *ngIf="imageDataCampagne" style="border:none; color:white;"><i class="fa fa-times-circle"></i></button>
        </div>
      </div>-->
      <!--<input matInput placeholder="Image" formControlName="file" hidden>
              </mat-form-field>
            </div>

            <div style="min-width: 23em;">
              <img *ngIf="imageDataCampagne" [src]="imageDataCampagne" alt="image Pub" style="height: 23em;" />
            </div>
          </div>


          <div style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: 5px;">
            <button mat-raised-button matStepperPrevious style="margin-right: 10px;">Retour</button>
            <button mat-raised-button color="primary" matStepperNext>Suivant</button>
          </div>
        </form>
      </mat-step>-->
      <!--budget-->
      <!--<mat-step [stepControl]="fourthFormGroup" class="budget">
        <form [formGroup]="fourthFormGroup">
          <ng-template matStepLabel>Budget</ng-template>
          <mat-form-field>
            <mat-label>Montant</mat-label>
            <input type="number" matInput placeholder="Montant" formControlName="montantCtrl" (change)="calculeNombre()" required>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Coût par vue</mat-label>
            <input type="number" matInput placeholder="Coût par vue" formControlName="coutParVueCtrl" required (change)="calculeNombre()">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input type="number" matInput placeholder="Nombre" formControlName="nombreCtrl" readonly>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Capping</mat-label>
            <input type="number" matInput placeholder="Capping" formControlName="cappingCtrl" value="1" required>
          </mat-form-field>
          <div>
            <button mat-raised-button matStepperPrevious style="margin-right: 10px;">Retour</button>
            <button mat-raised-button color="primary" matStepperNext>Suivant</button>
          </div>
        </form>
      </mat-step>-->
      <!--Date et heure-->
      <!--<mat-step [stepControl]="fithFormGroup" class="date">
        <form [formGroup]="fithFormGroup">
          <div class="divfithFormGroup">
            <ng-template matStepLabel>Date</ng-template>
            <mat-form-field appearance="fill">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker" [formGroup]="fithFormGroup">
                <input matStartDate placeholder="Start date" formControlName="debutCtrl">
                <input matEndDate placeholder="End date" formControlName="finCtrl">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <mat-card class="example-card plageHoraire">
              <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-title>Définir une plage Horaire</mat-card-title>
                <mat-card-subtitle>Facultatif</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div>
                  <mat-label>Heure de début</mat-label>
                  <div>
                    <ngb-timepicker [(ngModel)]="timeDebutCampagne" [ngModelOptions]="{standalone: true}"></ngb-timepicker>
                  </div>
                </div>
                <div>
                  <mat-label>Heure de Fin</mat-label>
                  <div>
                    <ngb-timepicker [(ngModel)]="timeFinCampagne" [ngModelOptions]="{standalone: true}"></ngb-timepicker>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div>
            <button mat-raised-button matStepperPrevious style="margin-right: 10px;">Retour</button>
            <button mat-raised-button color="primary" matStepperNext>Suivant</button>
          </div>
        </form>
      </mat-step>-->
      <!--filtre-->
      <!--<mat-step [stepControl]="sixthFormGroup" class="filtre">
        <form [formGroup]="sixthFormGroup">
          <ng-template matStepLabel>Filtre</ng-template>
          <mat-form-field>
            <mat-label>Filtre</mat-label>
            <input matInput placeholder="Filtre" formControlName="filtreCtrl" >
          </mat-form-field>
          <div>
            <button mat-raised-button matStepperPrevious style="margin-right: 10px;">Retour</button>
            <button mat-raised-button color="primary" matStepperNext>Suivant</button>
          </div>
        </form>
      </mat-step>-->
      <!--done-->
      <!--<mat-step>
              <ng-template matStepLabel>Résumé</ng-template>
              <div class="divGlobalResume">
                <div class="divResumeInformation">
                  <div class="sousDivResumeInformation">
                    <mat-card class="example-card cardResumeInformation">
                      <mat-card-header>
                        <div mat-card-avatar class="example-header-image"></div>
                        <mat-card-title>Nom de la capagne : {{firstFormGroup.get('nomCtrl').value}}</mat-card-title>
                      </mat-card-header>
                      <mat-card-content class="contentResumeInformation">
                        <mat-label>
                          Adresse : {{adresse}}
                        </mat-label>
                        <mat-label>
                          Rayon : {{rangeCampagne}}
                        </mat-label>
                        <mat-label>
                          Montant : {{fourthFormGroup.get('montantCtrl').value}}
                        </mat-label>
                        <mat-label>
                          Coût par vue : {{fourthFormGroup.get('coutParVueCtrl').value}}
                        </mat-label>
                        <mat-label>
                          Nombre : {{fourthFormGroup.get('nombreCtrl').value}}
                        </mat-label>
                        <mat-label>
                          Capping : {{fourthFormGroup.get('cappingCtrl').value}}
                        </mat-label>
                        <div class="dateResumeInformation">
                          <mat-label>
                            Date de début : {{fithFormGroup.value.debutCtrl | date: 'dd/MM/yyyy'}}
                          </mat-label>
                          <mat-label>
                            Date de fin : {{fithFormGroup.value.finCtrl | date : 'dd/MM/yyyy'}}
                          </mat-label>
                          <mat-label *ngIf="timeDebutCampagne">
                            Heure de début : {{calculeHeureMinute(timeDebutCampagne.hour)  + ':' +  calculeHeureMinute(timeDebutCampagne.minute) }}
                          </mat-label>
                          <mat-label *ngIf="timeFinCampagne">
                            Heure de fin : {{calculeHeureMinute(timeFinCampagne.hour) + ':' + calculeHeureMinute(timeFinCampagne.minute) }}
                          </mat-label>
                        </div>

                        <mat-label *ngIf="sixthFormGroup.get('filtreCtrl').value && sixthFormGroup.get('filtreCtrl').value.length > 0">
                          Filtre : {{sixthFormGroup.get('filtreCtrl').value}}
                        </mat-label>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
                <div class="divResumeContenue">

                  <mat-card class="example-card">
                    <mat-card-header>
                      <div mat-card-avatar class="example-header-image"></div>
                      <mat-card-title>{{thirdFormGroup.get('titleCtrl').value}}</mat-card-title>
                    </mat-card-header>
                    <img mat-card-image [src]="imageDataCampagne" alt="image Pub" style="height: 17.7em;">
                    <mat-card-content>
                      <p>
                        {{thirdFormGroup.get('bodyCtrl').value}}
                      </p>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
              <div>
                <p>Etes-vous sur de vouloir envoyer cette campagne ? </p>
                <div>
                  <button mat-raised-button matStepperPrevious style="margin-right: 10px;">Retour</button>
                  <button mat-raised-button color="primary" (click)="sendCampagne()">Créer</button>
                </div>
              </div>

            </mat-step>
          </mat-horizontal-stepper>





        </div>
      </mat-tab>-->
    </mat-tab-group>


    <!-- Modal -->
    <div class="modal fade" id="filtreModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="background-color:#242424;">
          <div class="modal-header">
            <h5 style="margin-left:20px; padding-top:20px;"><span style="color:#E57661">D&#201;FINISSEZ</span> VOS FILTRES</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" style="color:white;">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-default" style="min-width: 10em;">Nom</span>
              </div>
              <input type="text" [(ngModel)]="filtre.Nom" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
            </div>

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-default" style="min-width: 10em;">Prenom</span>
              </div>
              <input type="text" [(ngModel)]="filtre.Prenom" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
            </div>

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-default" style="min-width: 10em;">Date de naissance</span>
              </div>
              <input type="date" [(ngModel)]="filtre.DateNaissance" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
            </div>

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-default" style="min-width: 10em;">Lieu de naissance</span>
              </div>
              <input type="text" [(ngModel)]="filtre.LieuNaissance" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
            </div>

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-default" style="min-width: 10em;">Nationalité</span>
              </div>
              <input type="text" [(ngModel)]="filtre.Nationalite" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
            </div>

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-default" style="min-width: 10em;">Téléphone</span>
              </div>
              <input type="text" [(ngModel)]="filtre.Telephone" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeFiltre()">Close</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="saveFiltre()">Sauvegarder</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

