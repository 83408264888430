<div class="globalContainer">

  <div class="homeContainer2">

    <!--<h1 style="margin-left:20px; padding-top:20px;"><span style="color:#019FC2">Administration</span> Campagne</h1>-->

    <div class="globalDiv">
      <div class="rightDiv" *ngIf="campagnesSelected">

        <mat-vertical-stepper #stepper [selectedIndex]="selectedIndex">
          <mat-step>
            <ng-template matStepLabel>Actives</ng-template>
            <mat-nav-list class="lstCampagnes" *ngIf="lstCampagnesActives && lstCampagnesActives.length > 0">
              <mat-list-item *ngFor="let ca of lstCampagnesActives;" (click)="selectCampagne(ca)" [class]="campagnesSelected.Id == ca.Id ? 'select' : ''">
                {{ca.Nom}}
              </mat-list-item>
            </mat-nav-list>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Non Actives</ng-template>
            <mat-nav-list class="lstCampagnes" *ngIf="lstCampagnesPauses && lstCampagnesPauses.length > 0">
              <mat-list-item *ngFor="let cp of lstCampagnesPauses;" (click)="selectCampagne(cp)" [class]="campagnesSelected.Id == cp.Id ? 'select' : ''">
                {{cp.Nom}}
              </mat-list-item>
            </mat-nav-list>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Archivées</ng-template>
            <mat-nav-list class="lstCampagnes" *ngIf="lstCampagnesArchives && lstCampagnesArchives.length > 0">
              <mat-list-item *ngFor="let car of lstCampagnesArchives;" (click)="selectCampagne(car)" [class]="campagnesSelected.Id == car.Id ? 'select' : ''">
                {{car.Nom}}
              </mat-list-item>
            </mat-nav-list>
          </mat-step>
        </mat-vertical-stepper>
      </div>


      <!--body: "fsdqfsdfqsdf"
      campagne: []
      coordonnee: 30229
      coordonneeNavigation: null
      distance: 0
      filtrePub: []
      gainUser: []
      id: "94a99324-a6f4-49f2-a747-2db14527045e"
      idCampagne: null
      idCampagneNavigation: null
      imageData: null
      imageData2: null
      nbEnvoie: 0
      nbPlay: 0
      nom: "test campagne definitive                                                                            "
      notify: false
      prix: 2
      statistique: []
      titre: "gsqdgfsdf-->


      <div class="leftDiv" *ngIf="campagnesSelected">
        <h1>{{campagnesSelected.Nom}}</h1>

        <div style="display: flex; flex-direction: row; justify-content: space-between; margin: 2em; margin-bottom: 0px;">

          <div style="display: flex; flex-direction: column; ">
            <div style="display: flex; flex-direction: row; justify-content: space-around">
              <p style="width: 50%; text-align: left;"> Status : </p>
              <select style="width: 50%;height: 1.5em; margin: 1em;" [(ngModel)]="selectedStatus">
                <option value="1">Active</option>
                <option value="2">Non Active</option>
                <option value="3">Archivée</option>
              </select>
            </div>

            <div>
              <table style="width: 100%;margin-top: 2em;">
                <tbody>
                  <tr>
                    <td style=" text-align: left;"> Budget :</td>
                    <td style="text-align: right;"> <input [(ngModel)]="campagnesSelected.Montant" /> € </td>
                  </tr>
                  <tr>
                    <td style=" text-align: left;"> Budget restant :</td>
                    <td style="text-align: right;" *ngIf="statistique"> {{campagnesSelected.Montant - (statistique.NbVue * campagnesSelected.CoutParVue) }} € </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <table *ngIf="statistique" style="width: 100%; margin-top: 2em;">
                <tbody>
                  <tr>
                    <td style=" text-align: left;"> Nombre total de Push :</td>
                    <td style="text-align: right;"> {{statistique.NbEnvoie}}</td>
                  </tr>
                  <tr>
                    <td style=" text-align: left;"> Nombre total d'Acceptations :  </td>
                    <td style="text-align: right;"> {{statistique.NbNotifcation}} </td>
                  </tr>
                  <tr>
                    <td style=" text-align: left;"> Nombre total de Visionnages :  </td>
                    <td style="text-align: right;"> {{statistique.NbVue}} </td>
                  </tr>
                  <tr>
                    <td style=" text-align: left;"> Nombre total de Clients en magasin  </td>
                    <td style="text-align: right;"> 0 </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="divTableStatAdmin">

              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tableStatAdmin">

                <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->
                <!-- Position Column -->
                <ng-container matColumnDef="heure">
                  <th mat-header-cell *matHeaderCellDef> Heure </th>
                  <td mat-cell *matCellDef="let element"> {{element.heure}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="nbPush">
                  <th mat-header-cell *matHeaderCellDef> NB Push </th>
                  <td mat-cell *matCellDef="let element"> {{element.nbPush}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="acceptations">
                  <th mat-header-cell *matHeaderCellDef> Acceptations </th>
                  <td mat-cell *matCellDef="let element"> {{element.acceptations}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="visionnage">
                  <th mat-header-cell *matHeaderCellDef> Visionnage </th>
                  <td mat-cell *matCellDef="let element"> {{element.visionnage}} </td>
                </ng-container>

                <ng-container matColumnDef="clientMagasin">
                  <th mat-header-cell *matHeaderCellDef> Client en magasin </th>
                  <td mat-cell *matCellDef="let element"> {{element.clientMagasin}} </td>
                </ng-container>

                <ng-container matColumnDef="coutProspect">
                  <th mat-header-cell *matHeaderCellDef> Coût/Prospect </th>
                  <td mat-cell *matCellDef="let element"> {{element.coutProspect}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>


            </div>
          </div>

          <div style="display: flex; flex-direction: column; justify-content: space-around">
            <div style="display: flex; flex-direction: row; justify-content: space-around">
              <div style="margin: 10px;">
                <div class="dateGroupClass">
                  <mat-form-field class="datePickerClass" appearance="fill">
                    <mat-label>Date de début</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="campagnesSelected.DateDebut">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field class="datePickerClass" appearance="fill">
                    <mat-label>Date de fin</mat-label>
                    <input matInput [matDatepicker]="picker2" [(ngModel)]="campagnesSelected.DateFin">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>

                </div>
                <div style="display: flex; flex-direction: row;">

                  <div style="width: 10em;">
                    <mat-label>Heure de début</mat-label>
                    <div>
                      <ngb-timepicker [(ngModel)]="timeDebutCampagne" [ngModelOptions]="{standalone: true}"></ngb-timepicker>
                    </div>
                  </div>
                  <div style="width: 10em;">
                    <mat-label>Heure de Fin</mat-label>
                    <div>
                      <ngb-timepicker [(ngModel)]="timeFinCampagne" [ngModelOptions]="{standalone: true}"></ngb-timepicker>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="height: 22em; width: 15em;">
                  <img style="width: 100%;height: 100%;" src="../../assets/images/iphoneXS.png" />
                  <div class="apercuIphoneAdmin">
                    <div *ngIf="campagnesSelected && campagnesSelected.PubNavigation && campagnesSelected.PubNavigation.ImageData2" style="height: 60%;">
                      <img [src]="campagnesSelected.PubNavigation.ImageData2" style="width:100%; height: 100%;" />
                    </div>
                    <div style="overflow:hidden;height: 2em;margin-bottom: 7px;" *ngIf="campagnesSelected && campagnesSelected.PubNavigation && campagnesSelected.PubNavigation.Titre">
                      <h4 style="color: white;">{{campagnesSelected.PubNavigation.Titre}}</h4>
                    </div>
                    <div style="height:30%; margin-bottom:0px;" *ngIf="campagnesSelected && campagnesSelected.PubNavigation && campagnesSelected.PubNavigation.Body">
                      <p style="width:100%;word-wrap:break-word;height: 100%;overflow: hidden; color: white;">{{campagnesSelected.PubNavigation.Body}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="display: flex; flex-direction: column; justify-content: space-around; margin-top: 1em;">
              <div id="map" style="position:relative; margin-left: 10px; margin-right: 10px; color:black; height: 20em;"> </div>
              <div class="form-group" style="margin:20px;" *ngIf="campagnesSelected && campagnesSelected.PubNavigation && campagnesSelected.PubNavigation.Distance">
                <label for="formControlRange">Distance de publication : </label>
                <label slot="end" style="width:100px; margin-left : 20px;">{{campagnesSelected.PubNavigation.Distance < 1000 ? campagnesSelected.PubNavigation.Distance : campagnesSelected.PubNavigation.Distance/ 1000}} {{campagnesSelected.PubNavigation.Distance < 1000 ? 'm' : 'km'}}</label>
              </div>
              <div class="buttonAdminClass">
                <button mat-raised-button color="warn" (click)="annuler()">Annuler</button>
                <button mat-raised-button color="primary" (click)="save()">Enregistrer</button>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>
