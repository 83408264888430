<div class="modal-header">
  <img src="assets/image/logo.png" style="width:23%" />
</div>
<div class="modal-body">
  <h1 ion-text color="primary" style="text-align: center">{{titre}}</h1>

  <div *ngIf="id===0">
    <form>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-user"></i></span>
        </div>
        <input type="text" class="form-control" placeholder="Login" [(ngModel)]="login" name="login">
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-key"></i></span>
        </div>
        <input type="password" class="form-control" placeholder="Mot de passe" [(ngModel)]="password" name="password">
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-key"></i></span>
        </div>
        <input type="password" class="form-control" placeholder="Confirmation du mot de passe" [(ngModel)]="confPassword" name="confPassword">
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-address-card"></i></span>
        </div>
        <input type="text" class="form-control" placeholder="Nom" [(ngModel)]="nom" name="nom">
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-address-card"></i></span>
        </div>
        <input type="text" class="form-control" placeholder="Prenom" [(ngModel)]="prenom" name="prenom">
      </div>

      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-mobile"></i></span>
        </div>
        <input type="tel" class="form-control" placeholder="Telephone" [(ngModel)]="telephone" name="telephone">
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-at"></i></span>
        </div>
        <input type="email" class="form-control" placeholder="Adresse mail" [(ngModel)]="adresseMail" name="adresseMail">
      </div>

      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-home"></i></span>
        </div>
        <input type="text" class="form-control" placeholder="Adresse postale" [(ngModel)]="adresse" name="adresse">
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-road"></i></span>
        </div>
        <input type="text" class="form-control" placeholder="Ville" [(ngModel)]="ville" name="ville">
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-map-marked"></i></span>
        </div>
        <input type="text" class="form-control" placeholder="Code Postal" [(ngModel)]="codePostal" name="codePostal">
      </div>
      <div class="input-group form-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-globe-europe"></i></span>
        </div>
        <input type="text" class="form-control" placeholder="Pays" [(ngModel)]="pays" name="pays">
      </div>

    </form>

  </div>
  <div *ngIf="id===1">
    <div class="parentDivLoginInput">
      <div class="sousDivInpoutParent">

        <div class="itemLogin" *ngIf="!mailSend">
          <label class="labelInputLogin" for="mail">Adresse mail</label>
          <input class="inputLogin" type="text" id="mail" [(ngModel)]="mail" />
        </div>

        <div class="itemLogin" *ngIf="mailSend">
          <p>
            Votre demande de modification de mot de passe a bien été prise en compte le :
          </p>
          <p style="text-align: center; color: #0c1258;">{{date | date: 'EEEE d MMMM y' : '': 'fr-FR'}}</p>
          <p>
            Si le compte existe, vous recevrez un email à l'adresse :
          </p>
          <p style="text-align: center; color: #0c1258;">{{mail}}</p>
          <p>Cliquez sur le lien communiqué dans l'email afin de finaliser la procédure de réinitialisation</p>

        </div>

      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button *ngIf="!mailSend" type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Annuler</button>
  <button *ngIf="mailSend" type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Ok</button>
  <button *ngIf="id===0" type="button" ngbAutofocus class="btn btn-danger" (click)="onSubmit()">Enregistrer</button>
  <button *ngIf="id===1 && !mailSend" class="buttonLogin" (click)="valider()">Valider</button>
</div>
