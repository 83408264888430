import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppComponent } from '../app.component';
import { LoginComponent } from '../login/login.component';
import { urlConfig } from '../service/urlConfig.service';

@Component({
  selector: 'app-code-validation',
  templateUrl: './code-validation.component.html',
  styleUrls: ['./code-validation.component.scss']
})
export class CodeValidationComponent implements OnInit {
  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });

  enseigneSelected: any = {};
  lstEnseigne: any;
  enseigneModif: any = {};

  constructor(private http: HttpClient, private router: Router, private app: AppComponent, private messageService: MessageService) {
  }

  ngOnInit(): void {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }
    else {

      this.app.ensSelected.subscribe((ens: any) => {
        this.enseigneSelected = ens;
        this.enseigneClick(ens);
      });

    }

  }

  enseigneClick(ens) {
    this.enseigneSelected = ens;

    this.http.post(urlConfig.getUrl("getenseigneforsiteannonceur"), this.enseigneSelected).subscribe((res: any) => {

      if (res && res.data && res.data.ens) {
        this.enseigneSelected = res.data.ens;
      }

      this.enseigneModif = JSON.parse(JSON.stringify(this.enseigneSelected));

    });

  }

  submit() {

    this.http.post(urlConfig.getUrl("PutEnseigne"), this.enseigneModif).subscribe((res: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Code mis à jour avec succès.' });
      this.enseigneClick(this.enseigneSelected);
    });
  }

}
