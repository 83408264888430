import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  title = "";
  message = "";
  enableLoader = true;
  congrat = false;
  close = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<DialogComponent>) {
    if (data) {
      this.title = data.title || this.title;
      this.message = data.message || this.message;
      this.enableLoader = data.enableLoader;
      this.congrat = data.congrat;
      this.close = data.close
    }
  }

  ngOnInit() {
  }



}
