import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { DialogComponent } from '../dialog/dialog.component';
import { ListeEnseigneComponent } from '../liste-enseigne/liste-enseigne.component';
import { LoginComponent } from '../login/login.component';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { urlConfig } from '../service/urlConfig.service';

@Component({
  selector: 'app-annonce',
  templateUrl: './annonce.component.html',
  styleUrls: ['./annonce.component.scss']
})
export class AnnonceComponent {
  contrat = { siteWeb: false, animationClientele: false, communication: false };

  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });

  dernierMessage: any;
  addMessage = { IdEnseigne: 0, Text: "" };
  enseigneSelected: any;

  communication: { IdEnseigne: number, Titre: string, Description: string, Image: string } = { IdEnseigne: 0, Titre: "", Description: "", Image: "" };

  constructor(private http: HttpClient, private dialog: MatDialog, private router: Router, private app: AppComponent) {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {

    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }

    this.app.ensSelected.subscribe((ens: any) => {
      this.enseigneSelected = ens;
      this.enseigneClick(ens);
    });

    //this.app.ensSelected.next(ListeEnseigneComponent.savedEnseigneSelected);

  }

  enseigneClick(ens) {

    this.enseigneSelected = ens;

    this.contrat = {
      siteWeb: this.enseigneSelected.IdContratsNavigation.SiteWeb,
      animationClientele: this.enseigneSelected.IdContratsNavigation.AnimationClientele,
      communication: this.enseigneSelected.IdContratsNavigation.Communication
    };

    this.chargerMessage();

  }

  chargerMessage() {
    this.http.post(urlConfig.getUrl("getmessageenseigne"), this.enseigneSelected).subscribe((res: any) => {
      if (res.success) {
        this.dernierMessage = res.data;
        this.addMessage.Text = res.data.Text;
      }

    });
  }

  sendMessage() {

    this.addMessage.IdEnseigne = this.enseigneSelected.Id;

    this.dialog.open(DialogComponent, {
      data: {
        title: "Envoie message",
        message: 'Envoie en cours !!!',
        enableLoader: true,
        congrat: false
      }
    });

    this.http.post(urlConfig.getUrl("setmessageenseigne"), this.addMessage).subscribe((res: any) => {

      this.dialog.closeAll();

      if (res.success) {
        this.addMessage.Text = '';
        this.chargerMessage();
      }

    });

  }

}
