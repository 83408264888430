<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content style="text-align: center;">
  <mat-spinner *ngIf="enableLoader && !congrat" class="spinner"></mat-spinner>
  <span *ngIf="!enableLoader  && !congrat" style="color:red; font-size: 10em;" class="material-icons">
    error
  </span>
  <span *ngIf="congrat" class="material-icons" style="color:black; font-size: 10em;">
    important_devices
  </span>

  <p>{{message}}</p>

  <button mat-raised-button *ngIf="close" [mat-dialog-close]="true" color="primary">Fermer</button>

</div>
