import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { Router } from '@angular/router';
import { LoginComponent } from '../login/login.component';



@Component({
  selector: 'app-filtre',
  templateUrl: './filtre.component.html',
  styleUrls: ['./filtre.component.scss']
})
export class FiltreComponent implements OnInit {
  //urlPostPub: string = "https://localhost:57429/api/Command";
  urlPostPub: string = "https://207.180.246.59:57429/api/Command";


  constructor(private http: HttpClient, private router: Router) {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {

    

  }

}
