import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { urlConfig } from '../service/urlConfig.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  httpHeader = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  @Input() titre;
  @Input() id;

  //urlLogin: string = "https://207.180.246.59:57429/api/InteractionUser/CreerUser";
  //urlLogin: string = "https://localhost:5005/api/InteractionUser/CreerUser";

  login: string = "";
  password: string = "";
  confPassword: string = "";
  nom: string = "";
  prenom: string = "";
  telephone: string = "";
  adresseMail: string = "";
  adresse: string = "";
  ville: string = "";
  codePostal: string = "";
  pays: string = "";

  public static token: string;
  errorLogin: boolean = false;
  erroLoginMessage: string = "";

  mail = "";
  mailSend = false;
  date = new Date().toDateString();

  constructor(private http: HttpClient, private router: Router, public modal: NgbActiveModal) {

  }

  ngOnInit() {
   
  }

  onSubmit() {
    //var createdPub = {
    //  "Titre": this.titre,
    //  "Body": this.textPub,
    //  "ImageData": this.imageData,
    //  "Distance": this.range,
    //  "Longitude": HomeComponent.LatLngmarker.getLatLng().lng,
    //  "Latitude": HomeComponent.LatLngmarker.getLatLng().lat,
    //  "Nom": this.nomPub
    //};

    //this.dataJson = {
    //    "pub": createdPub,
    //    "filtre" : this.filtre
    //};

    if (this.id === 0) {

      return this.http.post(urlConfig.getUrl("creeruser"), {
        user: {
          login: this.login,
          password: this.password,
          nom: this.nom,
          prenom: this.prenom,
          telephone: this.telephone,
          type: 2,
          mail: this.adresseMail,
          adresse: this.adresse,
          ville: this.ville,
          codePostal: this.codePostal,
          pays: this.pays,
          mailVerifier: true
        }
      }).subscribe((res: any) => {
        if (res.success) {

          this.modal.close('Ok click');
        }
        else {
          this.errorLogin = true;
          this.erroLoginMessage = res.message;
        }
      }, error => {
        return { success: false, data: null, message: error };
      });
    }
    else {

      this.modal.close('Ok click');
    }
    
  }

  valider() {
    this.mailSend = true;
    this.http.post(urlConfig.getUrl("interaction") + "sendMailPassword", { login: this.mail }, this.httpHeader).subscribe(res => {

    });
  }

}
