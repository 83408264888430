import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { AcceptConditionsModalComponent } from '../accept-conditions-modal/accept-conditions-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements AfterViewInit {
  public static userLog: string = "mokmap";
  public static connected = false;
  public static usrConnected: any;

  isExpanded = false;

  constructor(public router: Router) {
    
  }

  ngAfterViewInit() {
    
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  statue() {
    return NavMenuComponent.connected;
  }

  usrLog() {
    return NavMenuComponent.userLog;
  }

  userClickMenu(item) {
    setTimeout(() => { this.addClassItem(item); }, 0);
  }

  addClassItem(item) {
    var b = document.querySelectorAll('.nav-item')
    var it = item + 'Link';
    b.forEach(element => {
      element.classList.remove('active');

      if (element.id == it) {
        element.classList.add('active');
      }
    });

  }
}
