import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-smartphone-display',
  templateUrl: './smartphone-display.component.html',
  styleUrls: ['./smartphone-display.component.scss']
})
export class SmartphoneDisplayComponent implements OnInit {

  @Input() image = "";
  @Input() titre: any;
  @Input() body: any;
  @Input() titreColor: any;
  @Input() bodyColor: any;
  @Input() backgroundSize: any;

  constructor() { }

  ngOnInit(): void {
  }

}
