<div *ngIf="contrat.communication" class="d-flex flex-row align-items-center justify-content-around childrenDiv" style="margin-top: 1em; height: 30em;">

  <div class="flex flex-column align-items-center justify-content-around" style="height: 20em; width: 100%;">
    <div class="flex flex-column align-items-center justify-content-around" style="width: 100%;">
      <div class="flex flex-column align-items-center justify-content-around" style="height: 5em; width: 100%;">
        <div>Rédigez votre message personnalisé. Celui-ci sera mis en avant lorsque vos clients accèdent aux jeux, à leur carte de fidélité ou à la fiche de votre établissement.</div>
        <!--<div *ngIf="dernierMessage" class="lastMsgCom"> Message Actuel : {{dernierMessage.Text}}</div>-->
      </div>
      <div class="inputDiv" style="margin-top: 1em; width: 100%;">
        <!--<form class="example-form">
        <mat-form-field class="example-form-field" appearance="fill">
          <input matInput #message [(ngModel)]="addMessage.Text" name="message" style="color: black" maxlength="140">
          <mat-hint align="start"><strong>nombre de caractères </strong> </mat-hint>
          <mat-hint align="end">{{addMessage && addMessage.Text.length}} / 140</mat-hint>
        </mat-form-field>
      </form>-->

        <form class="example-form" style="margin-bottom: 1em;">

          <div class="flex flex-column align-items-center justify-content-around">
            <span class="p-float-label">
              <textarea rows="5" cols="60" pInputTextarea id="message" [(ngModel)]="addMessage.Text" name="message" maxlength="140"></textarea>
              <label for="message">Message</label>
            </span>
            <small class="p-d-block">Nombre de caractères: {{addMessage && addMessage.Text.length}} / 140</small>
          </div>
        </form>

      </div>
    </div>

    <!--<button style="width: 10em;" class="boutonAnnuler" mat-raised-button (click)="addMessage.Text = '' ">Annuler</button>
    <button style="width: 10em;" mat-raised-button color="primary" (click)="sendMessage()">Enregistrer</button>-->

    <div class="flex flex-row align-items-center justify-content-around" style="width: 15em;">
      <p-button label="Enregistrer" [text]="true" [raised]="true" [rounded]="true" severity="success" (click)="sendMessage()"></p-button>
      <p-button label="Annuler" [text]="true" [raised]="true" [rounded]="true" severity="danger" (click)="addMessage.Text = '' "></p-button>
    </div>
    

  </div>

</div>

<div *ngIf="!contrat.communication" class="notGame">
  <div class="titleNotGame">Soyez visibles auprès de vos clients.</div>
  <div class="textNotGame">
    L’envoi de messages sur l’activité de votre commerce, permet une communication aux plus proches de vos clients par SMS, email, notification.
    Votre choix d’envoyer un message ciblé apportera du trafic client les jours de faible affluence.
  </div>

  <div class="textNotGame">
    Ce service fait partie de l’offre communication.
  </div>

  <div class="textNotGame">
    Pour bénéficier de cette offre ou visualiser une démo contacter nous au 05.61.446.449 ou à contact@flytagger.com
  </div>
</div>
