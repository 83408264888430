import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { urlConfig } from '../service/urlConfig.service';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-fidelite',
  templateUrl: './fidelite.component.html',
  styleUrls: ['./fidelite.component.scss']
})
export class FideliteComponent implements OnInit {
  contrat = { siteWeb: false, animationClientele: false, communication: false };

  //headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });
  fidelite = [];
  lotFidelite = [];
  fideliteWithGain: any;
  gainFidelite = [];
  pointTotal = 0;
  dateNow: Date = new Date(Date.now());
  semaine = 0;
  mois = 0;
  annee = 0;
  addLot = { IdEnseigne: 0, Lot: "", NbPoint: 0, Valeur: 0, MinAchat:0 };
  arrayNumber = Array.from({ length: 1000 }, (_, i) => i + 1);
  enseigneSelected: any;
  lstEnseigne: any;

  lstTypeFidelite: any;

  categorieSelected = false;
  tmpCategorie = "";

  idxEdit = -1;
  putLotTmp = { IdEnseigne: 0, Lot: "", NbPoint: 0, Valeur: 0, MinAchat: 0 };

  condAtt: any;
  //choixFideliteType: any;
  showDialog = false;
  showDialogType = false;
  constructor(private router: Router, private http: HttpClient, private dialog: MatDialog, private app: AppComponent, private messageService: MessageService) {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }

    this.app.ensSelected.subscribe((ens: any) => {
      this.enseigneSelected = ens;
      this.condAtt = this.enseigneSelected.ConditionAttribution;
      this.enseigneClick(ens);
    });

    //this.app.ensSelected.next(ListeEnseigneComponent.savedEnseigneSelected);

  }

  validAtt() {
    //this.dialog.open(DialogComponent, {
    //  data: {
    //    title: "Modification des conditions",
    //    message: 'Sauvegarde en cours !!!',
    //    enableLoader: true,
    //    congrat: false
    //  }
    //});

    this.enseigneSelected.ConditionAttribution = this.condAtt;

    this.http.post(urlConfig.getUrl("PutEnseigne"), this.enseigneSelected).subscribe((res: any) => {

      //this.dialog.closeAll();

      this.app.reloadEnseigne.next(true);
    });
  }

  //getListEnseigne() {
  //  this.dialog.open(DialogComponent, {
  //    data: {
  //      title: "Chargement des enseignes",
  //      message: 'Chargement en cours !!!',
  //      enableLoader: true,
  //      congrat: false
  //    }
  //  });

  //  this.http.post(urlConfig.getUrl("enseigne"), NavMenuComponent.usrConnected).subscribe((res: any) => {

  //    this.dialog.closeAll();

  //    this.lstEnseigne = res.data;

  //  });
  //}

  initEditLot() {
    this.putLotTmp = { IdEnseigne: 0, Lot: "", NbPoint: 0, Valeur: 0, MinAchat: 0 };

    this.idxEdit = -1;
  }

  editLot(lot: any) {
    this.putLotTmp = lot;
  }

  putLot() {

    //this.dialog.open(DialogComponent, {
    //  data: {
    //    title: "Modification du lot de fidélité",
    //    message: 'Sauvegarde en cours !!!',
    //    enableLoader: true,
    //    congrat: false
    //  }
    //});

    this.http.post(urlConfig.getUrl("putlotfidelite"), this.putLotTmp).subscribe((res: any) => {

      this.enseigneClick(this.enseigneSelected);
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'la modification a été effectué avec succès ' });
      //this.dialog.closeAll();
    });

  }

  enseigneClick(ens) {

    this.enseigneSelected = ens;

    console.log("this.enseigneSelected", this.enseigneSelected);
    this.putLotTmp = { IdEnseigne: 0, Lot: "", NbPoint: 0, Valeur: 0, MinAchat: 0 };
    this.idxEdit = -1;
    
    this.contrat = {
      siteWeb: this.enseigneSelected.IdContratsNavigation.SiteWeb,
      animationClientele: this.enseigneSelected.IdContratsNavigation.AnimationClientele,
      communication: this.enseigneSelected.IdContratsNavigation.Communication
    };

    this.loadFidelite();
    
  }

  getFidelite() {
    console.log("this.lstTypeFidelite", this.lstTypeFidelite);
    if (!this.lstTypeFidelite) {
      this.http.get(urlConfig.getUrl("getTypeFidelite")).subscribe((res3: any) => {

        this.lstTypeFidelite = res3;
        this.setFideliteEnseigne();
      });
    }
    else {
      this.setFideliteEnseigne();
    }
    
  }

  setFideliteEnseigne() {
    this.lstTypeFidelite.map(c => {
      if (c.Type == this.enseigneSelected.IdTypeFideite) {
        this.enseigneSelected.IdTypeFideite = c.Id;
      }
    });

    if (this.enseigneSelected.IdTypeFideite && this.enseigneSelected.IdTypeFideite > 0) {
      this.categorieSelected = true;
    }
    else {
      this.categorieSelected = false;
    }
  }

  loadFidelite() {

    this.http.post(urlConfig.getUrl("getlotfideliteforenseigne"), { "Id": this.enseigneSelected.Id }).subscribe((res: any) => {

      if (res.success) {
        this.lotFidelite = res.data;
        this.http.post(urlConfig.getUrl("getfideliteforannonceurwithgain"), { "Id": this.enseigneSelected.Id}).subscribe((res2: any) => {

          if (res2.success) {

            this.semaine = 0;
            this.mois = 0;
            this.annee = 0;
            this.pointTotal = 0;
            
            this.fideliteWithGain = res2.data;
            this.fidelite = this.fideliteWithGain.lstFideliteUser;
            this.gainFidelite = this.fideliteWithGain.lstGainFideliteUser;

            for (var i = 0; i < this.fidelite.length; i++) {
              this.pointTotal += this.fidelite[i].Point;
            }

            for (var i = 0; i < this.gainFidelite.length; i++) {
              let tmp = this.gainFidelite[i];
              let dt = new Date(tmp.Date);
              let tmpFidelite = this.fidelite.find(o => o.Id == this.gainFidelite[i].IdFidelite);

              if (this.getWeek(dt, this.dateNow) == 1) {
                this.semaine += tmpFidelite.Point;
              }

              if (dt.getMonth() == this.dateNow.getMonth()) {
                this.mois += tmpFidelite.Point;
              }

              if (dt.getFullYear() == this.dateNow.getFullYear()) {
                this.annee += tmpFidelite.Point;
              }

            }
          }
        });

      }
    });

    this.getFidelite();
  }


  getWeek(d: Date, d2: Date) {
    let currentdate = d;
    var oneJan = d2; // new Date(currentdate.getFullYear(), 0, 1);
    var tmpCurrentDate = Date.UTC(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate());
    var tmpOneJan = Date.UTC(oneJan.getFullYear(), oneJan.getMonth(), oneJan.getDate());
    var numberOfDays = Math.floor((tmpCurrentDate - tmpOneJan) / (24 * 60 * 60 * 1000));

    var result = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);

    return result;
  }

  saveLotFidelite() {
    if (this.categorieSelected) {
      if (this.addLot.Lot.length == 0) {
        //this.dialog.open(DialogComponent, {
        //  data: {
        //    title: "Erreur",
        //    message: 'Merci de renseigner un nom de lot !!!',
        //    enableLoader: false,
        //    congrat: false,
        //    close: true
        //  }
        //});
      }
      else if (this.addLot.NbPoint == 0) {
        //this.dialog.open(DialogComponent, {
        //  data: {
        //    title: "Erreur",
        //    message: 'Merci de renseigner un nombre de point pour le lot !!!',
        //    enableLoader: false,
        //    congrat: false,
        //    close: true
        //  }
        //});
      }
      else {

        //this.dialog.open(DialogComponent, {
        //  data: {
        //    title: "Savegarde du lot",
        //    message: 'Sauvegarde en cours !!!',
        //    enableLoader: true,
        //    congrat: false
        //  }
        //});

        this.addLot.IdEnseigne = this.enseigneSelected.Id;
        this.http.post(urlConfig.getUrl("saveLot"), this.addLot).subscribe((res: any) => {
          //this.dialog.closeAll();
          if (res.success) {
            this.loadFidelite();
            this.addLot = { IdEnseigne: this.enseigneSelected.Id, Lot: "", NbPoint: 0, Valeur: 0, MinAchat: 0 };
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'La sauvegarde a été effectué avec succès ' });
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'La sauvegarde a échouée ' });
          }

          this.enseigneClick(this.enseigneSelected);
        });
      }
    }

  }

  deleteLot(lot: any) {

    this.http.post(urlConfig.getUrl("deletelot"), lot).subscribe((res: any) => {
      //this.dialog.closeAll();
      if (res.success) {
        this.loadFidelite();
        this.addLot = { IdEnseigne: this.enseigneSelected.Id, Lot: "", NbPoint: 0, Valeur: 0, MinAchat: 0 };
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'La suppression a été effectuée avec succès ' });
        //this.dialog.open(DialogComponent, {
        //  data: {
        //    title: "Suppression lot",
        //    message: 'Lot supprimé avec succès',
        //    enableLoader: false,
        //    congrat: true,
        //    close: true
        //  }
        //});
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'La suppression a échouée ' });
        //this.dialog.open(DialogComponent, {
        //  data: {
        //    title: "Suppression lot",
        //    message: 'Erreur lors de la suppression du lot',
        //    enableLoader: false,
        //    congrat: false,
        //    close: true
        //  }
        //});
      }
      this.enseigneClick(this.enseigneSelected);
    });

  }

  animal: string;
  name: string;

  changeTypeFidelite() {

    this.showDialog = true;

    //this.enseigneSelected.IdTypeFideite = this.choixFideliteType;

    console.log("changeTypeFidelite", this.enseigneSelected);
    this.http.post(urlConfig.getUrl("PutEnseigne"), this.enseigneSelected).subscribe((res: any) => {

      if (res.success) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'La sauvegarde a été effectuée avec succès ' });

        this.showDialog = false;
        this.lstTypeFidelite.map(c => {
          if (c.Id == this.enseigneSelected.IdTypeFideite) {
            this.tmpCategorie = c.Nom;
          }
        });
      }
      else {
        this.enseigneSelected.IdTypeFideite = 0;
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'La sauvegarde a échouée ' });
      }
      this.enseigneClick(this.enseigneSelected);
    });

    

    

    //let dialogRef = this.dialog.open(DialogAvertissementComponent, {
    //  data: {
    //    title: "Attention !!!",
    //    message: 'Etes-vous sur de vouloir valider votre choix ?',
    //    message2: 'Vous avez selectionné le type :',
    //    message3: '' + this.tmpCategorie,
    //    message4: 'Votre choix sera définitif',
    //    displayImage: false
    //  }
    //});

    //dialogRef.afterClosed().subscribe(result => {

    //  if (result.valide) {
    //    this.dialog.open(DialogComponent, {
    //      data: {
    //        title: "Enregistrement du type de carte",
    //        message: 'Enregistrement en cours !!!',
    //        enableLoader: true,
    //        congrat: false
    //      }
    //    });

    //    this.http.post(urlConfig.getUrl("PutEnseigne"), this.enseigneSelected).subscribe((res: any) => {
    //      //this.dialog.closeAll();

    //    });
    //  }
      
    //});

  }

  closeClick() {
    //let dialogRef = this.dialog.open(DialogAvertissementComponent, {
    //  data: {
    //    title: "Les différentes catégories",
    //    displayImage: true
    //  }
    //});
    this.showDialogType = !this.showDialogType;
  }

}
