import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { AppComponent } from '../app.component';
import { DialogComponent } from '../dialog/dialog.component';
import { LoginComponent } from '../login/login.component';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { urlConfig } from '../service/urlConfig.service';

@Component({
  selector: 'app-liste-enseigne',
  templateUrl: './liste-enseigne.component.html',
  styleUrls: ['./liste-enseigne.component.scss']
})
export class ListeEnseigneComponent implements OnInit {

  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });

  public static savedEnseigneSelected: any;

  lstEnseigne: any;

  expand = false;

  enseigneSelected: any;

  public static contrat = { siteWeb: false, animationClientele: false, communication: false };

  clickUp = false;

  obMoveDiv: Subscription = new Subscription();

  constructor(private http: HttpClient, private dialog: MatDialog, private app: AppComponent) {

    this.getListEnseigne();

    this.app.ensSelected.subscribe((ens: any) => {
      this.enseigneSelected = ens;
      ListeEnseigneComponent.savedEnseigneSelected = ens;
    });

    this.app.reloadEnseigne.subscribe((val: any) => {
      if (val) {
        this.getListEnseigne();
      }
    });
  }

  ngOnInit(): void {
    
  }

  enseigneClick(ens) {
    this.enseigneSelected = ens;
    //this.app.ensSelected.next(ens);

    ListeEnseigneComponent.contrat = {
      siteWeb: this.enseigneSelected.IdContratsNavigation.SiteWeb,
      animationClientele: this.enseigneSelected.IdContratsNavigation.AnimationClientele,
      communication: this.enseigneSelected.IdContratsNavigation.Communication
    };

  }

  getListEnseigne() {
    this.dialog.open(DialogComponent, {
      data: {
        title: "Chargement des enseignes",
        message: 'Chargement en cours !!!',
        enableLoader: true,
        congrat: false
      }
    });

    this.http.post(urlConfig.getUrl("enseigne"), NavMenuComponent.usrConnected).subscribe((res: any) => {
      this.dialog.closeAll();
      this.lstEnseigne = res.data;
      //this.app.ensSelected.next(res.data[0]);
    });
  }

  moveDiv() {
    var divParent = document.getElementById("scrollEnseigne");
    this.obMoveDiv = fromEvent(document.body, 'mousemove').subscribe( (e: MouseEvent) => {
      divParent.style.left = e.pageX + "px";
      divParent.style.top = e.pageY + "px";

    });

    fromEvent(document.body, 'click').subscribe((e: MouseEvent) => {
      this.obMoveDiv.unsubscribe();
    });

  }
}
