<div class="parentContentPub">
  <div>

  </div>
  <div style="position: absolute;left: 15em; top: 1em; z-index: 10;" >
    <img title="close" style="width: 2em; height: 2em; color: white;" src="assets/icon/close-circle-outline.svg" />
  </div>
  <div [class]="backgroundSize == 1 ? 'divImg' : 'divImg divImgBackgroundCover'" [style]="'background-image : url(' + image + ')'">
    <!-- <img title="img" [src]="imgNotif" /> -->
  </div>
  <div class="divContentDataNotif">
    <h1 style="text-align: center; margin-bottom: 2em; color: white; font-size: 2em; width: 100%; overflow: hidden; " [style.color]="titreColor">{{titre}}</h1>
    <p style="width: 100%; text-align: center; color: white;" [style.color]="bodyColor">{{body}}</p>
  </div>
</div>
