import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { AppComponent } from '../app.component';
import { DialogComponent } from '../dialog/dialog.component';
import { ListeEnseigneComponent } from '../liste-enseigne/liste-enseigne.component';
import { LoginComponent } from '../login/login.component';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { urlConfig } from '../service/urlConfig.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-enseigne',
  templateUrl: './enseigne.component.html',
  styleUrls: ['./enseigne.component.scss']
})
export class EnseigneComponent implements OnInit {
  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });

  dernierMessage: any;
  addMessage = { IdEnseigne: 0, Text: "" };
  enseigneSelected: any = {};
  lstEnseigne: any;
  enseigneModif: any = {};

  items: MenuItem[] = [];
  activeIndex: number = 0;

  constructor(private http: HttpClient, private dialog: MatDialog, private router: Router, private app: AppComponent, private messageService: MessageService) {
  }

  ngOnInit(): void {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }
    else {

      this.app.ensSelected.subscribe((ens: any) => {
        this.enseigneSelected = ens;
        this.enseigneClick(ens);
      });

    }

  }

  enseigneClick(ens) {
    this.enseigneSelected = ens;

    this.http.post(urlConfig.getUrl("getenseigneforsiteannonceur"), this.enseigneSelected).subscribe((res: any) => {

      if (res && res.data && res.data.ens) {
        this.enseigneSelected = res.data.ens;
      }

      console.log("this.enseigneSelected", this.enseigneSelected);
      this.enseigneModif = JSON.parse(JSON.stringify(this.enseigneSelected));
      this.enseigneModif.Image = this.enseigneModif.Image;
      this.enseigneModif.Horraires.forEach((hor) => {
        this.items.push({ label: hor.Jour, icon: 'pi pi-clock' });
      });

    });

  }

  decrementIndex() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
  }

  incrementIndex() {
    if (this.activeIndex < this.enseigneModif.Horraires.length - 1) {
      this.activeIndex++;
    }
  }




  getImageEnseigne(id) {
    this.http.get(urlConfig.getUrl("getimagefileenseigne") + id).subscribe(r => {
      return r;
    });
  }

  submit() {
    this.http.post(urlConfig.getUrl("PutEnseigne"), this.enseigneModif).subscribe((res: any) => {

      //this.dialog.closeAll();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Informations enregistrées avec succès.' });
      this.enseigneClick(this.enseigneSelected);
    });
  }

  annulerModif() {
    this.enseigneModif = JSON.parse(JSON.stringify(this.enseigneSelected));
  }

  onFileChange(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      var filesize: number = parseFloat(((file.size / 1024) / 1024).toFixed(4));
      var typeMatch = this.isImage(file.name);

      if (!typeMatch) {
        window.alert("Vous ne pouvez envoyer que des fichiers image au format PNG, JPG, JPEG, BMP de moins de 4Mo");
      }
      else if (filesize > 4) {
        window.alert("Votre image dépasse 4Mo, veillez à télécharger iniquement des images de moins de 4Mo");
      }
      else {
        reader.onload = () => {
          this.enseigneModif.Image = reader.result as string;
        };
      }

    }
  }



  getExtension(filename) {
    var parts = filename.split('.');

    return parts[parts.length - 1];
  }

  isImage(filename) {
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'bmp':
      case 'png':
        return true;
    }
    return false;
  }

}
