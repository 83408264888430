import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { LoginComponent } from '../login/login.component';
import { urlConfig } from '../service/urlConfig.service';


import { PinturaEditorComponent } from '@pqina/angular-pintura';
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';

// pintura
import {
  LocaleCore,
  LocaleCrop,
  LocaleFinetune,
  LocaleFilter,
  LocaleAnnotate,
  LocaleMarkupEditor,
  LocaleDecorate,
  LocaleFill,
  LocaleFrame,
  LocaleRedact,
  LocaleResize,
  LocaleSticker,
  LocaleTrim
} from '@pqina/pintura/locale/fr_FR';

import {
  // editor
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,

  // plugins
  setPlugins,
  plugin_crop,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_filter,
  plugin_filter_defaults,
  plugin_annotate,
  markup_editor_defaults,
  plugin_decorate,
  plugin_fill,
  plugin_frame,
  plugin_frame_defaults,
  plugin_redact,
  plugin_resize,
  plugin_sticker,
  createDefaultMediaWriter,
  imageStateToCanvas,
  appendDefaultEditor,
  getEditorDefaults,
  openDefaultEditor,
  // filepond
  legacyDataToImageState,
  openEditor,
  processImage,
} from '@pqina/pintura';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';

import { plugin_trim, createDefaultVideoWriter, createMediaStreamEncoder } from '@pqina/pintura-video';

setPlugins(plugin_trim,plugin_finetune, plugin_filter, plugin_annotate, 
  // plugin_decorate,
  // plugin_fill,
  plugin_frame,
  plugin_redact,
  plugin_resize,
  plugin_sticker, plugin_crop);

  
interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  @ViewChild('editor') editor?: any;
  @ViewChild('inlineEditor') inlineEditor?: PinturaEditorComponent<any> = undefined;
  @ViewChild('fileUpload') fileUpload: FileUpload;

  //@ViewChild('imageInput') imgPubComUpload: ElementRef;
  contrat = { siteWeb: false, animationClientele: false, communication: false };
  
  //@ViewChild('imageeditor') imageObj: ImageEditorComponent;

  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });

  enseigneSelected: any;

  communication: { IdEnseigne: number, Titre: string, Description: string, Image: string } = { IdEnseigne: 0, Titre: "", Description: "", Image: "" };
  backgroundSize = 1;
  colorTitre = "white";
  colorBody = "white";

  showDialog = false;

  imgUpdated:any;

  // editor generic state
  editorOptions: any = {
    imageCropAspectRatio: 9 / 16,
    statusMessage: 'Importez un fichier pour commencer : image ou vidéo au format portrait (9/16), ou un PDF',
    allowImageCropAspectRatioChange: false,
    imageReader: createDefaultImageReader(),
    imageWriter: createDefaultMediaWriter(
      // Generic writer options, passed to image and video writer
      {
          targetSize: {
              width: 320,
          },
      },
      [
          createDefaultImageWriter({
              // Specific image writer options
          }),
          createDefaultVideoWriter({
              // Specific video writer options
              encoder: createMediaStreamEncoder({
                // Required
                imageStateToCanvas,

                 // Default output quality (optional)
                audioBitrate: 64000,
                videoBitrate: 2000000,

                // Default FPS (optional)
                //framesPerSecond: 24,
                // By default logging is disabled
                log: true
              })
          }),
      ]
    ),
    imageTrim: [[0.5, 0.7]],
    shapePreprocessor: createDefaultShapePreprocessor(),
    ...plugin_finetune_defaults,
    ...plugin_filter_defaults,
    ...markup_editor_defaults,
    locale: {
      ...LocaleTrim,
      ...LocaleCore,
      ...LocaleCrop,
      ...LocaleFinetune,
      ...LocaleFilter,
      ...LocaleAnnotate,
      ...LocaleMarkupEditor,
      // ...LocaleDecorate,
      // ...LocaleFill,
      ...LocaleFrame,
      ...LocaleRedact,
      ...LocaleResize,
      ...LocaleSticker
    },
    imageSize: { width: 320, height: 569 }
  };

  stickers: any = [
          '😀', '😁', '😂', '🤣', '😃', '😄', '😅', '😆', '😉', '😊', '😋', '😎', '😍', '😘', '😗', '😙', '😚', '🙂', '🤗', '🤩', '🤔', '🤨', '😐', '😑', '😶', '🙄', '😏', '😣', '😥', '😮', '🤐', '😯', '😪', '😫', '😴', '😌', '😛', '😜', '😝', '🤤', '😒', '😓', '😔', '😕', '🙃', '🤑', '😲', '☹', '🙁', '😖', '😞', '😟', '😤', '😢', '😭', '😦', '😧', '😨', '😩', '🤯', '😬', '😰', '😱', '😳', '🤪', '😵', '😡', '😠', '🤬', '😷', '🤒', '🤕', '🤢', '🤮', '🤧', '😇', '🤠', '🤥', '🤫', '🤭', '🧐', '🤓', '😈', '👿', '🤡', '👹', '👺', '💀', '☠', '👻', '👽', '👾', '🤖', '💩', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '😿', '😾', '🙈', '🙉', '🙊', '🤳', '💪', '👈', '👉', '☝', '👆', '🖕', '👇', '✌', '🤞', '🖖', '🤘', '🤙', '🖐', '✋', '👌', '👍', '👎', '✊', '👊', '🤛', '🤜', '🤚', '👋', '🤟', '✍', '👏', '👐', '🙌', '🤲', '🙏', '🤝', '💅', '👂', '👃', '👣', '👀', '👁', '👁️‍🗨️', '🧠', '👅', '👄',
          ' ⌚️', ' 📱', ' 📲', ' 💻', ' ⌨️', ' 🖥', ' 🖨', ' 🖱', ' 🖲', ' 🕹', ' 🗜', ' 💽', ' 💾', ' 💿', ' 📀', ' 📼', ' 📷', ' 📸', ' 📹', ' 🎥', ' 📽', ' 🎞', ' 📞', ' ☎️', ' 📟', ' 📠', ' 📺', ' 📻', ' 🎙', ' 🎚', ' 🎛', ' ⏱', ' ⏲', ' ⏰', ' 🕰', ' ⌛️', ' ⏳', ' 📡', ' 🔋', ' 🔌', ' 💡', ' 🔦', ' 🕯', ' 🗑', ' 🛢', ' 💸', ' 💵', ' 💴', ' 💶', ' 💷', ' 💰', ' 💳', ' 💎', ' ⚖️', ' 🔧', ' 🔨', ' ⚒', ' 🛠', ' ⛏', ' 🔩', ' ⚙️', ' ⛓', ' 🔫', ' 💣', ' 🔪', ' 🗡', ' ⚔️', ' 🛡', ' 🚬', ' ⚰️', ' ⚱️', ' 🏺', ' 🔮', ' 📿', ' 💈', ' ⚗️', ' 🔭', ' 🔬', ' 🕳', ' 💊', ' 💉', ' 🌡', ' 🚽', ' 🚰', ' 🚿', ' 🛁', ' 🛀', ' 🛀🏻', ' 🛀🏼', ' 🛀🏽', ' 🛀🏾', ' 🛀🏿', ' 🛎', ' 🔑', ' 🗝', ' 🚪', ' 🛋', ' 🛏', ' 🛌', ' 🖼', ' 🛍', ' 🛒', ' 🎁', ' 🎈', ' 🎏', ' 🎀', ' 🎊', ' 🎉', ' 🎎', ' 🏮', ' 🎐', ' ✉️', ' 📩', ' 📨', ' 📧', ' 💌', ' 📥', ' 📤', ' 📦', ' 🏷', ' 📪', ' 📫', ' 📬', ' 📭', ' 📮', ' 📯', ' 📜', ' 📃', ' 📄', ' 📑', ' 📊', ' 📈', ' 📉', ' 🗒', ' 🗓', ' 📆', ' 📅', ' 📇', ' 🗃', ' 🗳', ' 🗄', ' 📋', ' 📁', ' 📂', ' 🗂', ' 🗞', ' 📰', ' 📓', ' 📔', ' 📒', ' 📕', ' 📗', ' 📘', ' 📙', ' 📚', ' 📖', ' 🔖', ' 🔗', ' 📎', ' 🖇', ' 📐', ' 📏', ' 📌', ' 📍', ' ✂️', ' 🖊', ' 🖋', ' ✒️', ' 🖌', ' 🖍', ' 📝', ' ✏️', ' 🔍', ' 🔎', ' 🔏', ' 🔐', ' 🔒', ' 🔓',
          ' 🌑', ' 🌒', ' 🌓', ' 🌔', ' 🌕', ' 🌖', ' 🌗', ' 🌘', ' 🌙', ' 🌚', ' 🌛', ' 🌜', ' 🌡', ' ☀', ' 🌝', ' 🌞', ' ⭐', ' 🌟', ' 🌠', ' ☁', ' ⛅', ' ⛈', ' 🌤', ' 🌥', ' 🌦', ' 🌧', ' 🌨', ' 🌩', ' 🌪', ' 🌫', ' 🌬', ' 🌀', ' 🌈', ' 🌂', ' ☂', ' ☔', ' ⛱', ' ⚡', ' ❄', ' ☃', ' ⛄', ' ☄', ' 🔥', ' 💧', ' 🌊', ' 🚂', ' 🚃', ' 🚄', ' 🚅', ' 🚆', ' 🚇', ' 🚈', ' 🚉', ' 🚊', ' 🚝', ' 🚞', ' 🚋', ' 🚌', ' 🚍', ' 🚎', ' 🚐', ' 🚑', ' 🚒', ' 🚓', ' 🚔', ' 🚕', ' 🚖', ' 🚗', ' 🚘', ' 🚙', ' 🚚', ' 🚛', ' 🚜', ' 🚲', ' 🛴', ' 🛹', ' 🛵', ' 🚏', ' 🛣', ' 🛤', ' 🛢', ' ⛽', ' 🚨', ' 🚥', ' 🚦', ' 🛑', ' 🚧', ' ⚓', ' ⛵', ' 🛶', ' 🚤', ' 🛳', ' ⛴', ' 🛥', ' 🚢', ' ✈', ' 🛩', ' 🛫', ' 🛬', ' 💺', ' 🚁', ' 🚟', ' 🚠', ' 🚡', ' 🛰', ' 🚀', ' 🛸', ' 🍏', ' 🍎', ' 🍐', ' 🍊', ' 🍋', ' 🍌', ' 🍉', ' 🍇', ' 🍓', ' 🍈', ' 🍒', ' 🍑', ' 🍍', ' 🥥', ' 🥝', ' 🍅', ' 🍆', ' 🥑', ' 🥦', ' 🥒', ' 🌶', ' 🌽', ' 🥕', ' 🥔', ' 🍠', ' 🥐', ' 🍞', ' 🥖', ' 🥨', ' 🧀', ' 🥚', ' 🍳', ' 🥞', ' 🥓', ' 🥩', ' 🍗', ' 🍖', ' 🌭', ' 🍔', ' 🍟', ' 🍕', ' 🥪', ' 🥙', ' 🌮', ' 🌯', ' 🥗', ' 🥘', ' 🥫', ' 🍝', ' 🍜', ' 🍲', ' 🍛', ' 🍣', ' 🍱', ' 🥟', ' 🍤', ' 🍙', ' 🍚', ' 🍘', ' 🍥', ' 🥠', ' 🍢', ' 🍡', ' 🍧', ' 🍨', ' 🍦', ' 🥧', ' 🍰', ' 🎂', ' 🍮', ' 🍭', ' 🍬', ' 🍫', ' 🍿', ' 🍩', ' 🍪', ' 🌰', ' 🥜', ' 🍯', ' 🥛', ' 🍼', ' ☕️', ' 🍵', ' 🥤', ' 🍶', ' 🍺', ' 🍻', ' 🥂', ' 🍷', ' 🥃', ' 🍸', ' 🍹', ' 🍾', ' 🥄',
          ' 🍴', ' 🍽', ' 🥣', ' 🥡', ' 🥢', ' 🐶', ' 🐱', ' 🐭', ' 🐹', ' 🐰', ' 🦊', ' 🐻', ' 🐼', ' 🐨', ' 🐯', ' 🦁', ' 🐮', ' 🐷', ' 🐽', ' 🐸', ' 🐵', ' 🙈', ' 🙉', ' 🙊', ' 🐒', ' 🐔', ' 🐧', ' 🐦', ' 🐤', ' 🐣', ' 🐥', ' 🦆', ' 🦅', ' 🦉', ' 🦇', ' 🐺', ' 🐗', ' 🐴', ' 🦄', ' 🐝', ' 🐛', ' 🦋', ' 🐌', ' 🐚', ' 🐞', ' 🐜', ' 🦗', ' 🕷', ' 🕸', ' 🦂', ' 🐢', ' 🐍', ' 🦎', ' 🦖', ' 🦕', ' 🐙', ' 🦑', ' 🦐', ' 🦀', ' 🐡', ' 🐠', ' 🐟', ' 🐬', ' 🐳', ' 🐋', ' 🦈', ' 🐊', ' 🐅', ' 🐆', ' 🦓', ' 🦍', ' 🐘', ' 🦏', ' 🐪', ' 🐫', ' 🦒', ' 🐃', ' 🐂', ' 🐄', ' 🐎', ' 🐖', ' 🐏', ' 🐑', ' 🐐', ' 🦌', ' 🐕', ' 🐩', ' 🐈', ' 🐓', ' 🦃', ' 🕊', ' 🐇', ' 🐁', ' 🐀', ' 🐿', ' 🦔', ' 🐾', ' 🐉', ' 🐲', ' 🌵', ' 🎄', ' 🌲', ' 🌳', ' 🌴', ' 🌱', ' 🌿', ' ☘️', ' 🍀', ' 🎍', ' 🎋', ' 🍃', ' 🍂', ' 🍁', ' 🍄', ' 🌾', ' 💐', ' 🌷', ' 🌹', ' 🥀', ' 🌺', ' 🌸', ' 🌼', ' 🌻'
        ];

  // inline
  inlineSrc: string = 'assets/image.jpeg';
  inlineResult?: string = undefined;
  inlineCropAspectRatio = 9 / 16;
  pdfEnable = false;
  videoInput = false;
  pdfFile: any;

  constructor(private http: HttpClient, private router: Router, private app: AppComponent, private sanitizer: DomSanitizer, private messageService: MessageService, private pdfViewerService: NgxExtendedPdfViewerService) {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }

    this.app.ensSelected.subscribe((ens: any) => {
      this.enseigneSelected = ens;
      this.enseigneClick(ens);
    });

  }

  public async export(): Promise<void> {
    var blob = await this.pdfViewerService.getCurrentDocumentAsBlob();

    this.convertBlobToBase64(blob).then( (base64String: string )=> {
      this.inlineResult = base64String;  
      
      this.sendCommunication();
    });

  }

  convertBlobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });
  }

  handleInlineProcess($event: any) {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Data = reader.result;
      this.inlineResult = base64Data as string;
      this.sendCommunication();
    };

    reader.readAsDataURL($event.dest);

  }

  onImageUpload(event): void {
    const file: File = event.files[0];

    if (file.type.match('image.*')) {
      this.pdfEnable = false;
      this.videoInput = false;
      this.handleButtonClick(file);
      //this.resizeImage(file, resizedFile => {
      //  this.handleButtonClick(resizedFile);
      //});
    } else if (file.type === 'application/pdf') {
      this.pdfEnable = true;
      const fileURL = URL.createObjectURL(file);
      this.pdfFile = fileURL;
    } else if (file.type.match('video.*')){
      this.pdfEnable = false;
      this.videoInput = true;
      this.handleButtonClick(file);
    }
     else {
      this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Fichier non pris en charge ' });
    }

    this.fileUpload.clear();
    
  }

  resizeImage(file, callback) {
    const reader = new FileReader();

    reader.onload = (e) => {
        const img = new Image();

        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Ratio cible (9/16)
            const targetRatio = 9/16;

            // Ajuster la largeur de l'image pour correspondre au ratio 9/16 de la hauteur originale
            const newWidth = img.naturalHeight * targetRatio;
            
            // La hauteur reste la même que celle de l'image originale
            const newHeight = img.naturalHeight;

            canvas.width = newWidth;
            canvas.height = newHeight;

            // Dessiner l'image redimensionnée sur le canvas
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // Convertir le canvas en Blob
            canvas.toBlob(blob => {
                const resizedFile = new File([blob], file.name, {
                    type: 'image/png',
                    lastModified: Date.now()
                });

                callback(resizedFile);
            }, 'image/png');
        };

        img.src = (e.target.result as string);
    };

    reader.readAsDataURL(file);
  }








  handleButtonClick(file: File): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.inlineSrc = event.target.result;

        this.editor.editor.editImage(event.target.result).then((imageWriterResult) => {
        });
    };
    reader.readAsDataURL(file);
  }


  sendCommunication() {
    //this.dialog.open(DialogComponent, {
    //  data: {
    //    title: "Envoi de la communication",
    //    message: 'Chargement en cours !!!',
    //    enableLoader: true,
    //    congrat: false
    //  }
    //});

    this.showDialog = true;

    this.communication.Image = this.inlineResult;

    this.communication.IdEnseigne = this.enseigneSelected.Id;

    this.http.post(urlConfig.getUrl("sendCommunication"), { PubCommunication: this.communication, backgroundSize: this.backgroundSize, colorTitre: this.colorTitre, colorBody: this.colorBody }).subscribe((res: any) => {

      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Votre communication a été envoyé avec succès ' });
      this.pdfEnable = false;
      this.inlineResult = undefined;
      this.communication = { IdEnseigne: 0, Titre: "", Description: "", Image: "" };
      this.showDialog = false;
    },
      (error) => {
        console.error("Erreur lors de l'envoi de la communication :", error);
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur s’est produite lors de l’envoi de votre communication.' });
        this.showDialog = false;
      });

  }

  enseigneClick(ens) {

    this.enseigneSelected = ens;

    this.contrat = {
      siteWeb: this.enseigneSelected?.IdContratsNavigation?.SiteWeb,
      animationClientele: this.enseigneSelected?.IdContratsNavigation?.AnimationClientele,
      communication: this.enseigneSelected?.IdContratsNavigation?.Communication
    };

  }


  onFileChange(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      var filesize: number = parseFloat(((file.size / 1024) / 1024).toFixed(4));
      var typeMatch = this.isImage(file.name);

      if (!typeMatch) {
        window.alert("Vous ne pouvez envoyer que des fichiers image au format PNG, JPG, JPEG, BMP de moins de 4Mo");
      }
      else if (filesize > 4) {
        window.alert("Votre image dépasse 4Mo, veillez à télécharger uniquement des images de moins de 4Mo");
      }
      else {
        reader.onload = () => {
          this.communication.Image = reader.result as string;
        };
      }

    }
  }

  getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  isImage(filename) {
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'bmp':
      case 'png':
        return true;
    }
    return false;
  }


  public onUploadSuccess(args: any): void {
    if (args.operation === 'upload') {
      // Assurez-vous que le fichier uploadé est une image
      const imageUrl = args.file.url; // L'URL du fichier uploadé
      if (this.isImageFile(args.file.name)) {
        //this.imageObj.open(imageUrl);
      }
    }

  }

  private isImageFile(fileName: string): boolean {
    // Vérifiez si le fichier est une image en fonction de l'extension du fichier
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    const fileType = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
    return acceptedImageTypes.includes(fileType);
  }

}
