import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { DialogComponent } from '../dialog/dialog.component';
import { ListeEnseigneComponent } from '../liste-enseigne/liste-enseigne.component';
import { LoginComponent } from '../login/login.component';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { urlConfig } from '../service/urlConfig.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  contrat = { siteWeb: false, animationClientele: false, communication: false };
  @ViewChild('imageInput') imgPubComUpload: ElementRef;

  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });

  dernierMessage: any;
  addMessage = { IdEnseigne: 0, Text : ""} ;
  enseigneSelected: any;

  communication: { IdEnseigne: number, Titre: string, Description: string, Image: string } = { IdEnseigne: 0, Titre: "", Description: "", Image : "" };
  backgroundSize = 1;
  colorTitre = "white";
  colorBody = "white";

  constructor(private http: HttpClient, private dialog: MatDialog, private router: Router, private app: AppComponent) {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {

    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }

    this.app.ensSelected.subscribe((ens: any) => {
      this.enseigneSelected = ens;
      this.enseigneClick(ens);
    });

    //this.app.ensSelected.next(ListeEnseigneComponent.savedEnseigneSelected);

  }

  sendCommunication() {
    this.dialog.open(DialogComponent, {
      data: {
        title: "Envoi de la communication",
        message: 'Chargement en cours !!!',
        enableLoader: true,
        congrat: false
      }
    });

    this.communication.IdEnseigne = this.enseigneSelected.Id;

    this.http.post(urlConfig.getUrl("sendCommunication"), { PubCommunication: this.communication, backgroundSize: this.backgroundSize, colorTitre: this.colorTitre, colorBody: this.colorBody }).subscribe((res: any) => {

      this.dialog.closeAll();
      this.communication = { IdEnseigne: 0, Titre: "", Description: "", Image: "" };
      this.imgPubComUpload.nativeElement.value = null;
    });

  }

  enseigneClick(ens) {

    this.enseigneSelected = ens;

    this.contrat = {
      siteWeb: this.enseigneSelected.IdContratsNavigation.SiteWeb,
      animationClientele: this.enseigneSelected.IdContratsNavigation.AnimationClientele,
      communication: this.enseigneSelected.IdContratsNavigation.Communication
    };

    this.chargerMessage();

  }

  chargerMessage() {
    this.http.post(urlConfig.getUrl("getmessageenseigne"), this.enseigneSelected).subscribe((res: any) => {

      console.log("res", res);
      if (res.success) {
        this.dernierMessage = res.data;
      }

    });
  }

  sendMessage() {

    this.addMessage.IdEnseigne = this.enseigneSelected.Id;

    this.dialog.open(DialogComponent, {
      data: {
        title: "Envoie message",
        message: 'Envoie en cours !!!',
        enableLoader: true,
        congrat: false
      }
    });

    this.http.post(urlConfig.getUrl("setmessageenseigne"), this.addMessage).subscribe((res: any) => {

      this.dialog.closeAll();

      if (res.success) {
        this.addMessage.Text = '';
        this.chargerMessage();
      }

    });

  }

  onFileChange(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      var filesize: number = parseFloat(((file.size / 1024) / 1024).toFixed(4));
      var typeMatch = this.isImage(file.name);

      if (!typeMatch) {
        window.alert("Vous ne pouvez envoyer que des fichiers image au format PNG, JPG, JPEG, BMP de moins de 4Mo");
      }
      else if (filesize > 4) {
        window.alert("Votre image dépasse 4Mo, veillez à télécharger iniquement des images de moins de 4Mo");
      }
      else {
        reader.onload = () => {
          this.communication.Image = reader.result as string;
        };
      }

    }
  }

  getExtension(filename) {
    var parts = filename.split('.');
    console.log("extension : ", parts[parts.length - 1]);
    return parts[parts.length - 1];
  }

  isImage(filename) {
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'bmp':
      case 'png':
        return true;
    }
    return false;
  }

}
