import { ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from './service/breadcrumb.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];

  constructor(public layoutService: LayoutService, private breadcrumbService: BreadcrumbService, private activatedRoute: ActivatedRoute, private router: Router) { }
  
  ngOnInit() {
    this.model = [
      {
        label: 'Accueil',
        items: [
          { label: 'Tableau de bord', icon: 'pi pi-chart-bar', routerLink: ['Dashboard'], title: 'Accueil' }
        ]
      },
        {
          label: 'Animation Clientèle',
            items: [
              { label: 'Jeux', icon: 'pi pi-gift', routerLink: ['jeux'], title: 'Animation Clientèle' },
              { label: 'Fidélité', icon: 'pi pi-cart-plus', routerLink: ['fidelite'], title: 'Animation Clientèle' }
            ]
        },
        {
          label: 'Communication',
          items: [
            { label: 'Annonces', icon: 'pi pi-megaphone', routerLink: ['annonce'], title: 'Communication' },
            { label: 'Notifications', icon: 'pi pi-mobile', routerLink: ['notification'], title: 'Communication' }
          ]
        },
        {
          label: 'Etablissement',
          items: [
                {
                  label: 'Site Standard',
                  icon: 'pi pi-globe',
                  routerLink: ['siteStandard'],
                  title: 'Etablissement'
                },
                {
                  label: 'Enseigne',
                  icon: 'pi pi-home',
                  routerLink: ['enseigne'],
                  title: 'Etablissement'
                }
            ]
      },
      {
        label: 'Configuration',
        items: [
          {
            label: 'Application Utilisateur',
            icon: 'pi pi-mobile',
            routerLink: ['application_Utilisateur'],
            title: 'Configuration'
          },
          {
            label: 'Application Pro',
            icon: 'pi pi-server',
            routerLink: ['application_pro'],
            title: 'Configuration'
          }
        ]
      }
    ];

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateBreadcrumb();
      }
    });

    this.updateBreadcrumb();
  }

  updateBreadcrumb() {
    const path = this.router.url;  // récupère l'URL actuelle
    const pathSegments = path.split('/');  // divise l'URL en segments
    const lastSegment = pathSegments[pathSegments.length - 1];  // prend le dernier segment

    // Cherche le menu correspondant dans le modèle
    const menuItem = this.findMenuItemByRoute(lastSegment);

    if (menuItem) {
      let breadcrumbItems: MenuItem[] = [];

      // Ajoute l'élément cliqué
      breadcrumbItems.push({ label: menuItem.label, routerLink: menuItem.routerLink });

      // Recherche les parents et les ajoute au fil d'Ariane
      let parentLabel = menuItem.title;
      while (parentLabel) {
        const parentItem = this.findMenuItemByLabel(parentLabel);

        if (parentItem) {
          if (parentItem.label !== 'Accueil' || breadcrumbItems.length === 0) {
            breadcrumbItems = [{ label: parentItem.label, routerLink: parentItem.routerLink }, ...breadcrumbItems];
          }
          parentLabel = parentItem.title;
        } else {
          break;
        }
      }

      this.breadcrumbService.setBreadcrumb(breadcrumbItems);
    }
  }


  findMenuItemByLabel(label: string): MenuItem {
    // Rechercher l'élément de menu par son label dans votre structure de menu
    for (const menuItem of this.model) {
      if (menuItem.label === label) {
        return menuItem;
      }

      if (menuItem.items) {
        for (const subItem of menuItem.items) {
          if (subItem.label === label) {
            return subItem;
          }
        }
      }
    }
    return null;
  }

  findMenuItemByRoute(route: string): MenuItem {
    // Cherche l'élément de menu par sa route dans votre structure de menu
    for (const menuItem of this.model) {
      if (menuItem.items) {
        for (const subItem of menuItem.items) {
          if (subItem.routerLink && subItem.routerLink[0] === route) {
            return subItem;
          }
        }
      }
    }
    return null;
  }

  openStore(os: any) {
    switch (os.toLowerCase()) {
      case "android":
        window.open("https://play.google.com/store/apps/details?id=io.flytaggerPro.app");
        break;
      case "ios":
        window.open("https://apps.apple.com/us/app/flytagger/id1658634072");
        break;
    }
  }

}
