import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-accept-conditions-modal',
  templateUrl: './accept-conditions-modal.component.html',
  styleUrls: ['./accept-conditions-modal.component.scss']
})
export class AcceptConditionsModalComponent {
  @Input() display: boolean = false;
  isChecked = false;

  constructor() {}

  onAccept(): void {
    this.display = false;
  }
}
