import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { urlConfig } from '../service/urlConfig.service';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { DialogComponent } from '../dialog/dialog.component';
import { LoginComponent } from '../login/login.component';
import { Router } from '@angular/router';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Result } from '@zxing/library';
import { HomeComponent } from '../home/home.component';
import { AppComponent } from '../app.component';
import { ListeEnseigneComponent } from '../liste-enseigne/liste-enseigne.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-create-lot-jeux',
  templateUrl: './create-lot-jeux.component.html',
  styleUrls: ['./create-lot-jeux.component.scss']
})
export class CreateLotJeuxComponent implements OnInit {
  contrat = { siteWeb: false, animationClientele: false, communication: false };

  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });

  idxEdit = -1;
  putLotTmp = {
    Chance: 0,
    Gagne: 0,
    IdAnnonceur: 0,
    IdEnseigne: 0,
    IdJeux: 0,
    Nom: "",
    Nombre: 0,
    MinAchat: 0,
    Valeur: 0,
    Id:0
  };

  newEnseigne : {
    Nom: "",
    Image: (string | ArrayBuffer),
    Adresse: "",
    CodePostal: "",
    Ville: "",
    IdAnnonceur: ""
  } = {
      Nom: "",
      Image: "",
      Adresse: "",
      CodePostal: "",
      Ville: "",
      IdAnnonceur: ""
    };
  lstNewlotsEnseigne = [];
  lotsEnseigne: any;
  enseigneSelected: any;
  enableErreur = "errurDisplayNone";
  messageErreur = "";
  imgColor = "blue";
  createLot = {
    Chance: 0,
    Gagne: 0,
    IdAnnonceur: 0,
    IdEnseigne: 0,
    IdJeux: 0,
    Nom: "",
    Nombre: 0,
    MinAchat: 0,
    Valeur:0
  };
  nbChance = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];
  chancePerdre = 100;
  toggleClass = false;

  condValidation: any;
  constructor(private http: HttpClient, private dialog: MatDialog, private router: Router, private app: AppComponent, private messageService: MessageService) {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.app?.ensSelected?.subscribe((ens: any) => {
      this.enseigneSelected = ens;
      if (this.enseigneSelected.ConditionValide == null) {
        this.condValidation = " ";
      }
      else {
        this.condValidation = this.enseigneSelected.ConditionValide;
      }
      
      this.enseigneClick(ens);
    });

  }

  ngAfterViewInit() {
    
  }

  validCond() {
    //this.dialog.open(DialogComponent, {
    //  data: {
    //    title: "Modification des conditions",
    //    message: 'Sauvegarde en cours !!!',
    //    enableLoader: true,
    //    congrat: false
    //  }
    //});

    this.enseigneSelected.ConditionValide = this.condValidation;

    this.http.post(urlConfig.getUrl("PutEnseigne"), this.enseigneSelected).subscribe((res: any) => {
      this.app.reloadEnseigne.next(true);
    });

  }

  initEditLot() {
    this.putLotTmp = {
      Chance: 0,
      Gagne: 0,
      IdAnnonceur: 0,
      IdEnseigne: 0,
      IdJeux: 0,
      Nom: "",
      Nombre: 0,
      MinAchat: 0,
      Valeur: 0,
      Id:0
    };

    this.idxEdit = -1;

    this.getLot();

  }

  editLot(lot: any, index: number) {
    console.log("lot",lot);
    this.putLotTmp = lot;
    this.idxEdit = index;
  }

  putLot() {
    if (this.putLotTmp.Nom.length == 0) {
      this.messageErreur = "Vous devez saisir un nom !!!";
    }
    else if (this.putLotTmp.Nombre == 0) {
      this.messageErreur = "Le nombre de cadeaux a gagner ne peut pas être égal à 0 !!!";
    }
    else if (this.putLotTmp.Chance == 0) {
      this.messageErreur = "La chance de remporter le lot doit être supérieur à 0% !!!";
    }
    else {
      this.chancePerdre = 100;
      this.lotsEnseigne.forEach((i: any) => {
        if (i != null) {
          if (i.Nom != "Perdu") {
            this.chancePerdre -= i.Chance;
          }
        }
      });

      var tmpLot = this.lotsEnseigne.find(o => o.Id == this.putLotTmp.Id);

      var tmpChange = this.chancePerdre + tmpLot.Chance;

      tmpChange -= this.putLotTmp.Chance;      

      if (tmpChange < 0) {
        this.messageErreur = "Vous ne pouvez pas cumuler plus de 100% de chances de gagner sur l’ensemble des lots";
      }
      else {
        this.messageErreur = "";
        this.chancePerdre = tmpChange;
      }
    }

    if (this.messageErreur.length > 0) {
      this.enableErreur = "displayErreur";

      setTimeout(() => { this.enableErreur = "errurDisplayNone"; }, 5000);
      this.getLot();
    }
    else {
      this.http.post(urlConfig.getUrl("putlot"), this.putLotTmp).subscribe((res: any) => {

        this.getLot();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'la modification a été effectué avec succès ' });
      });
    }

  }

  enseigneClick(ens) {

    this.enseigneSelected = ens;

    this.initEditLot();

    this.contrat = {
      siteWeb: this.enseigneSelected.IdContratsNavigation.SiteWeb,
      animationClientele: this.enseigneSelected.IdContratsNavigation.AnimationClientele,
      communication: this.enseigneSelected.IdContratsNavigation.Communication
    };

    this.chancePerdre = 100;
    
  }

  getLot() {
    this.chancePerdre = 100;
    this.http.post(urlConfig.getUrl("lot"), this.enseigneSelected).subscribe((res: any) => {
      if(res?.data != null){
        this.lotsEnseigne = res.data.reduce((c: any, i: any) => {
          if (i != null && c != null) {
            if (i.Nom != "Perdu") {
              c.push(i);
              this.chancePerdre -= i.Chance;
            }
            return c;
          }
        }, []);
      }
      
    });
  }

  addLot() {

    if (this.createLot.Nom.length == 0) {
      this.messageErreur = "Vous devez saisir un nom !!!";
    }
    else if (this.createLot.Nombre == 0) {
      this.messageErreur = "Le nombre de cadeaux a gagner ne peut pas être égal à 0 !!!";
    }
    else if (this.createLot.Chance == 0) {
      this.messageErreur = "La chance de remporter le lot doit être supérieur à 0% !!!";
    }
    else {
      var tmpChange = this.chancePerdre;
      tmpChange -= this.createLot.Chance;
      
      if (tmpChange < 0) {
        this.messageErreur = "Vous ne pouvez pas cumuler plus de 100% de chances de gagner sur l’ensemble des lots";
      }
      else {
        this.messageErreur = "";
        this.chancePerdre = tmpChange;
      }
    }

    if (this.messageErreur.length > 0) {
      this.enableErreur = "displayErreur";

      setTimeout(() => { this.enableErreur = "errurDisplayNone"; }, 5000);
    }
    else {
      this.createLot.IdAnnonceur = NavMenuComponent.usrConnected.id;
      this.createLot.IdEnseigne = this.enseigneSelected.Id;
      this.createLot.IdJeux = 1;
      this.lstNewlotsEnseigne.push(this.createLot);
      this.lotsEnseigne.push(this.createLot);

      this.saveLot();

    }
    
  }

  isNewLot(lot: any): boolean {
    return this.lstNewlotsEnseigne.includes(lot);
  }

  saveLot() {
    this.http.post(urlConfig.getUrl("setLotJeux"), this.lstNewlotsEnseigne).subscribe((res: any) => {
      if (res.success) {
        this.getLot();
        this.lstNewlotsEnseigne = [];
        this.idxEdit = -1;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'La sauvegarde a été effectué avec succès ' });

        this.createLot = {
          Chance: 0,
          Gagne: 0,
          IdAnnonceur: 0,
          IdEnseigne: 0,
          IdJeux: 0,
          Nom: "",
          Nombre: 0,
          MinAchat: 0,
          Valeur: 0
        };
      }
    });

  }

  annuleAddLot() {
    
    this.lstNewlotsEnseigne.map(c => {
      this.chancePerdre += c.Chance;
    });

    this.lstNewlotsEnseigne = [];
    this.createLot = {
      Chance: 0,
      Gagne: 0,
      IdAnnonceur: 0,
      IdEnseigne: 0,
      IdJeux: 0,
      Nom: "",
      Nombre: 0,
      MinAchat: 0,
      Valeur:0
    };

    this.idxEdit = -1;
  }

  clearFile() {
    this.newEnseigne.Image = "";
    (<HTMLInputElement>document.getElementById("fileInput")).value = "";
  }

  deleteLot(lot) {
    this.http.post(urlConfig.getUrl("deleteLotJeux"), lot).subscribe((res: any) => {
      if (res.success) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'La suppression a été effectué avec succès ' });
      }

      this.getLot();
    },
    error=> {
      this.getLot();
    });
  }

  deleteNewLot(lot) {

    this.chancePerdre += lot.Chance;

    this.lstNewlotsEnseigne = this.lstNewlotsEnseigne.reduce((c: any, i: any) => {

      if (i.Nom != lot.Nom) {
        c.push(i);
      }
      return c;
    }, []);

  }

  tailleLotEns() {
    return !this.lotsEnseigne || this.lotsEnseigne.length < 6;
  }
}
