<div class="card flex flex-column justify-content-around align-items-center" style="width: 100%; box-shadow: 0px 4px 11px -4px #000; margin-bottom: 10px;" *ngIf="feeBack">
    <div class="txtTitleDiv">
      Statistiques du jour
    </div>
    <div class="flex flex-row justify-content-around align-items-center" style="width: 100%;">
      <span></span>
      <div class="infoStat">
        <p-chart type="bar" [data]="dataClientsAujourdhuiParHeure" class="chart-custom"></p-chart>
        <div >
          Nouveaux clients au total : <span class="lowStockColor"> {{sumArrayElements(feeBack.ClientsAujourdhuiParHeure)}} </span>
        </div>
      </div>

      <div class="infoStat">
        <p-chart type="bar" [data]="dataPointsAttribuesAujourdhuiParHeure" class="chart-custom"></p-chart>
        <div >
          Nombre de points total : <span class="lowStockColor"> {{sumArrayElements(feeBack.PointsAttribuesAujourdhuiParHeure)}} </span>
        </div>
      </div>

      <div class="infoStat">
        <p-chart type="bar" [data]="dataGainsConsommesAujourdhuiParHeure" class="chart-custom"></p-chart>
        <div >
          Nombre de lots retiré au total : <span class="lowStockColor"> {{sumArrayElements(feeBack.GainsConsommesAujourdhuiParHeure)}} </span>
        </div>
      </div>
    </div>
  </div>


<div class="card flex flex-column justify-content-around align-items-center" style="width: 100%; box-shadow: 0px 4px 11px -4px #000; padding: 10px 1px 10px 1px !important;" *ngIf="feeBack">
  <div class="txtTitleDiv">
    Statistiques globales
  </div>
  <div class="flex flex-row justify-content-around align-items-center flex-wrap" style="width: 100%;">
    <div class="infoStat">
      <p-chart type="line" [data]="dataClientsParJour" class="chart-custom"></p-chart>
    </div>
    <div class="infoStat">
      <p-chart type="line" [data]="dataClientsParSemaine" class="chart-custom"></p-chart>
    </div>
    <div class="infoStat">
      <p-chart type="line" [data]="dataClientsParMois" class="chart-custom"></p-chart>
    </div>
  </div>

  <div class="flex flex-row justify-content-around align-items-center flex-wrap" style="width: 100%;">
    <div class="infoStat">
      <p-chart type="line" [data]="dataGainsParJour" class="chart-custom"></p-chart>
      <div >
        Nombre de clients au total : <span class="lowStockColor"> {{feeBack.ngClientTotal}} </span>
      </div>
    </div>
    <div class="infoStat">
      <p-chart type="bar" [data]="dataGainsConsommesParJour" class="chart-custom"></p-chart>
      <div >
        Nombre de lots gagnés au total : <span class="lowStockColor"> {{feeBack.nbGainGagne}} </span>
      </div>
    </div>
    <div class="infoStat">
      <p-chart type="bar" [data]="dataPointsParJour" class="chart-custom"></p-chart>
      <div >
        Nombre de points gagnés au total : <span class="lowStockColor"> {{feeBack.pointCumuler}} </span>
      </div>
    </div>

  </div>

</div>

<p-dialog [(visible)]="showModalCGV">
  <div class="flex flex-column align-items-center justify-content-center px-8 py-5 gap-4" style="border-radius: 12px; height: 80vh; color: black;">
    <h1 style="color: black !important; text-align: center;">Vous devez lire et accepter les conditions générales et le règlement du jeu.</h1>
    <div class="parrentCgvRond">
      <div class="cgvRond" [style.background-color]="pdfEtape == 0 ? 'aquamarine' : 'lightgray'">1</div>
      <div class="cgvRond" [style.background-color]="pdfEtape == 0 ? 'lightgray' : 'aquamarine'">2</div>
    </div>

    <div *ngIf="pdfEtape == 0" class="textRGPD">
      <h3 class="titleCGV" style="text-align: center; width: 100%;">Conditions générales - Contrat de référencement et d’utilisation de la solution FLYTAGGER</h3>
      <br>
      <p>Les présentes conditions générales détaillent les droits et obligations de :</p>
      <div class="flex flex-column align-items-center justify-content-center" style="width: 100%;">
        <ul style="width: 70%;">
          <li>
            La société FLYTAGGER, Société par actions simplifiée au capital de 26.000 euros,
            dont le siège social est au 5 allée du Docteur Ludwik Lejzer Zamenhof, Bâtiment
            Ernst Porte 111, 31100 TOULOUSE, immatriculée au registre du commerce et des
            sociétés de TOULOUSE sous le numéro 884 030 719, représentée par son Président,
            Monsieur Romuald PAGNUCCO (ci-après dénommée le « Prestataire »)
          </li>
          <br>
          <p>et</p>
          <!-- <li style="list-style-type: none;">et</li> -->
          <li>Du signataire du devis joint (ci-après dénommé le « Client »).</li>
        </ul>
      </div>
      
      <p style="text-align: end;">Ensemble désignées « les Parties », et individuellement « la Partie »,</p>
      <p>Ces conditions générales forment avec le devis accepté joint un ensemble indivisible dans l’intention des Parties.</p>

      <strong>A titre préalable, il est indiqué que :</strong>
      <p>Le Prestataire a développé une solution visant à renforcer les liens entre les commerçants et leurs
        clients, afin d’améliorer la satisfaction de ces derniers et leur fidélisation.</p>
      <p>Cette solution dénommée FLYTAGGER permet notamment :</p>
      <div class="flex flex-column align-items-center justify-content-center" style="width: 100%;">
        <ul style="width: 70%;">
          <li>le référencement du commerçant</li>
          <li>l’édition et la gestion de cartes de fidélité / de bons de réduction</li>
          <li>la mise en place de jeux</li>
          <li>la gestion et la promotion d’évènements</li>
          <li>la communication clients</li>
          <li>le développement de solutions e-commerce, click & collect, invendus</li>
          <li>la simplification du dépôt d’avis clients.</li>
          <li>la création et l’hébergement de sites internet</li>
          <li>la réservation de services (table, billet spectacles …)</li>
          <li>le paiement depuis le smartphone</li>
        </ul>
      </div>
      
      <p>Ci-après le « Service » ou les « Services »</p>
      <p>Il est ici précisé que certains de ces Services sont en développement continu et que d’autres
        fonctionnalités viendront progressivement s’ajouter. Ces Services sont pour partie disponibles via
        le site internet flytagger.com et pour partie via les applications FLYTAGGER, disponibles sur IOS
        et Android.</p>
      <p>Le Client, après avoir été informé des modalités de fonctionnement de ces Services a souhaité
        conclure le présent contrat de référencement et d’utilisation de la solution FLYTAGGER.</p>
        <h3 class="titleCGV">ARTICLE 1 : CHOIX DES SERVICES</h3>
        <p>La solution FLYTAGGER est constituée de différents packs, décrits dans la documentation
          commerciale remise au Client, dont ce dernier reconnaît avoir pris connaissance :</p>
        <p>Pack site Web (offert)</p>
        <p>Pack animation clientèle (offert)</p>
        <p>Pack communication (39,99 euros ht par mois, sans engagement) ou (359,88 euros ht si le client
          accepte de prépayer comptant un an)</p>
        <p>Le client peut choisir indépendamment, un de ces 3 packs. Les packs offerts n’engagent aucun
          frais, ni engagement, ni abonnement à un pack payant.</p>
        <p>Après avoir été dûment informé des fonctionnalités techniques développées, le Client a opté pour
          un plusieurs des pack(s) suivant(s), conformément aux mentions figurant dans le devis joint :</p>
        <ul style="list-style-type: none;">
          <li>Pack site Web (offert)</li>
          <li>Pack animation clientèle (offert)</li>
          <li>Pack communication (39,99 euros ht par mois, sans engagement) ou (359,88 euros ht si le
            client accepte de prépayer comptant un an)</li>
        </ul>
        <p>Des prestations additionnelles peuvent être convenues et feront, le cas échéant, l’objet d’un
          avenant spécifique ou d’un devis.</p>
        <h3 class="titleCGV">ARTICLE 2. DEBUT ET FIN DU CONTRAT – FACTURATION</h3>
        <p>Notre Service consiste à vous mettre en relation avec des tiers (hébergeurs de services
          d’évaluation, commerçants partenaires, etc.). Nous ne garantissons pas les offres de ces sociétés
          et n’engageons pas notre responsabilité pour le contenu de leurs sites internet ou applications,
          produits ou services. Vous devez par conséquent prendre connaissance avec attention de leurs
          conditions d'utilisation, de service et/ou de vente.</p>
          <strong>Qualité du Service et responsabilité</strong>
          <p>Conjointement à la signature des présentes, le Client se voit remettre un identifiant et un mot de
            passe lui permettant d’accéder à la plateforme en ligne FLYTAGGER (url :
            https://pro.flytagger.com) et de paramétrer en cours d’exécution de la prestation les
            fonctionnalités souhaitées conformément aux packs choisis. En début d’exécution du contrat, les
            fonctionnalités seront configurées et la mise en service assurée par le Prestataire.</p>
          <p>Les Parties conviennent que la facturation débute à la signature des présentes, via un système de
            prélèvement SEPA (Gocardless.com) ou de payement par carte bancaire (Stripe.com). La
            3
            facturation se fera en début de mois pour le mois en cours, ou dès le début d’une année pour le
            prépaiement d’une année.</p>
        <h3 class="titleCGV">ARTICLE 3. RESILIATION</h3>
        <p>Le présent contrat pourra être résilié par chacune des Parties, à tout moment et sans indemnité.
          Aucune motivation de la demande de résiliation ne sera demandée.</p>
          <p>Pour des raisons techniques, un délai de 24 heures ouvrées peut s’appliquer entre la réception de
            la demande de résiliation et la cessation effective du Service.</p>
        <h3 class="titleCGV">ARTICLE 4. RESPONSABILITE DU PRESTATAIRE</h3>
        <p>Le Prestataire est tenu d’une obligation de moyen et fera ses meilleurs efforts pour assurer la
          disponibilité du Service. Il ne saurait cependant d’aucune manière être tenu pour responsable en
          cas de préjudice direct ou indirect causé par un dysfonctionnement quelconque, tel que,
          notamment, une utilisation frauduleuse ou une interruption, fut-elle prolongée, de
          fonctionnement de tout ou partie du Service.</p>
        <h3 class="titleCGV">ARTICLE 5. RESPONSABILITE DU CLIENT</h3>
        <p>L’identifiant et le mot de passe du Service doivent être conservés de manière confidentielle par le
          Client, sous son entière responsabilité. En cas de perte ou de vol, le Client le notifiera sans délai au
          Prestataire.</p>
        <p>Le Client s’engage à utiliser le Service conformément à ses finalités et à ne pas y introduire de
          données ou informations erronées ou illicites.</p>
        <p>Le Client informera sans délai le Prestataire de tout changement intervenu dans les informations
            mentionnées aux présentes (raison sociales, siège, etc.) ou de toute ouverture de procédure
            collective à son égard.</p>
        <h3 class="titleCGV">ARTICLE 6. DONNEES A CARACTERE PERSONNEL</h3>
        <p>Le Prestataire agit en qualité de responsable du traitement à l’égard des données à caractère
          personnel traitées dans le cadre de son site internet et de ses applications, en ce qu’il en détermine
          les finalités et moyens.</p>
        <p>Les utilisateurs du site www.flytagger.com et des applications FLYTAGGER contractent avec le
          Prestataire et disposent de droits mentionnés dans les conditions générales applicables à ces
          services.</p>
        <p>Pour toute question sur les données personnelles, veuillez contacter notre DPO à l'adresse:
          <a href="mailto:dpo@flytagger.com">dpo@flytagger.com</a>
        </p>
        <h3 class="titleCGV">ARTICLE 7. CLAUSE REPUTEE NON ÉCRITE</h3>
        <p>Si une ou plusieurs dispositions des présentes sont tenues pour non-valides par une loi ou un
          règlement, ou déclarées telles par décision définitive d'une juridiction compétente, elles seront
          4
          réputées non-écrites, les autres dispositions des présentes garderont toute leur force et leur
          portée.</p>
        <h3 class="titleCGV">ARTICLE 8. DOMICILIATION ET CONTACTS</h3>
        <p>Pour l’exécution du Contrat et de ses suites, les Parties élisent domicile en leur siège social
          respectif.<br> En cas de difficulté et pour toute question, le Client peut contacter le Prestataire aux coordonnées
          suivantes :</p>
        <p>
          EMAIL : <a href="mailto:service.client@flytagger.com">service.client@flytagger.com</a> 
          <br>
          Téléphone : <a href="tel:05 61 446 449">05 61 446 449</a> 
        </p>
        <h3 class="titleCGV">ARTICLE 9. RÉFÉRENCES</h3>
        <p>Chacune des Parties est autorisée à citer l’autre Partie dans ses références commerciales et sur
          tous supports d’information promotionnelle.
          Sur simple avis écrit, la Partie citée en référence pourra demander à l’autre Partie de suspendre
          cette communication.</p>
        <h3 class="titleCGV">ARTICLE 10. LOI APPLICABLE – ATTRIBUTION DE COMPETENCE</h3>
        <p>Le Contrat et les relations entre les Parties sont régies et interprétées conformément à la loi
          française. En cas de litige relatif à la validité, l'interprétation, l'exécution des présentes conditions
          qui n’aurait pas été réglé par la voie amiable, il est fait attribution de compétence aux tribunaux
          compétents du ressort du siège social du Prestataire, nonobstant pluralité de défendeurs ou appel
          en garantie.</p>
    </div>

    <div *ngIf="pdfEtape == 1" class="textRGPD">
      <h3 class="titleCGV" style="text-align: center; width: 100%;">REGLEMENT DU JEU « FLYTAGGER »</h3>
      <br>
      <h3 class="titleCGV">ARTICLE 1 – SOCIETE ORGANISATRICE</h3>
      <p>Le jeu est organisé par la SAS FLYTAGGER, Société par actions simplifiée au capital de 26.000 euros, dont le siège social est au 5 allée du Docteur Ludwik Lejzer Zamenhof, Bâtiment Ernst Appartement 111, 31100 TOULOUSE, immatriculée au registre du commerce et des sociétés de TOULOUSE sous le numéro 884 030 719 (ci-après « la Société Organisatrice »).</p>
      
      <h3 class="titleCGV">ARTICLE 2. DEBUT ET FIN DU CONTRAT – FACTURATION</h3>
      <p>Le jeu est gratuit et ouvert à toute personne majeure, résidant en France ou à l’étranger, quelle que soit sa nationalité, à l’exclusion des membres du personnel de la Société Organisatrice et de ses associés.
        <br>
        La participation au jeu-concours implique l’acceptation irrévocable et sans réserve, des termes et conditions du présent règlement.
        <br>
        La participation au jeu-concours est strictement personnelle. Elle est limitée à une participation par jour et par établissement partenaire.
        <br>
        Le non-respect des conditions de participation énoncées dans le présent règlement entraînera la nullité de la participation du participant.
      </p>
        
      <h3 class="titleCGV">ARTICLE 3 –PRINCIPE DU JEU/MODALITÉS DE PARTICIPATIO</h3>
      <p>Ce jeu se déroule exclusivement sur l’application de la Société Organisatrice ou le site internet généré par la Société Organisatrice pour chaque commerçant partenaire.</p>
        <p>Le participant (i) choisi l’établissement partenaire de son choix et (ii) fait tourner la roue en cliquant sur l’écran. La roue se met à tourner et indique si le participant a gagné un prix ou s’il a perdu.</p>

      <h3 class="titleCGV">ARTICLE 4 – DOTATIONS ET RESPONSABILITE</h3>
      <p>Les dotations à remporter sont définies par chaque commerçant partenaire, sous sa seule responsabilité, la Société Organisatrice agissant en qualité de simple prestataire technique. Les dotations peuvent prendre la forme de bons de réduction ou de produits gratuits sous réserve d’un minimum d’achat.</p>
      <p>La Société Organisatrice ne saurait voir sa responsabilité engagée en cas de dysfonctionnement empêchant l’accès et/ou le bon déroulement du jeu ou d’indisponibilité de la réduction ou du produit gratuit. Elle peut également mettre fin à tout moment au jeu ou en modifier les modalités, sans encourir de quelconque responsabilité.</p>
      <p>L’utilisation de robots ou de tous autres procédés similaires permettant de participer au jeu de façon automatisée, multiple ou autre est proscrite, la violation de cette règle entraînant l’élimination définitive du ou des participant(s) concerné(s).</p>
      
      <h3 class="titleCGV">ARTICLE 5 - PROTECTION DES DONNÉES PERSONNELLES</h3>
      <p>Dans le cadre du présent jeu, la politique de protection des données personnelles de FLYTAGGER, rappelée ci-après, s’applique.</p>
      <p>Ainsi, conformément à la loi n°78-17 du 6 janvier 1978 modifiée ainsi qu’au Règlement 2016/679 du Parlement européen et du Conseil du 27 avril 2016 (RGPD), vous disposez d’un droit d’accès, d’opposition, de rectification, d’effacement, de portabilité de vos données personnelles et d’un droit à la limitation du traitement de ces mêmes données. Ces droits vous permettent notamment, en tant que de besoin, de faire rectifier, compléter, mettre à jour ou effacer les données personnelles vous concernant qui seraient inexactes, incomplètes, équivoques, périmées ou dont la collecte, l'utilisation, la communication ou la conservation serait interdite. Vous disposez également du droit de former une réclamation auprès de la CNIL (www.cnil.fr).</p>
      <p>Vous pouvez également nous confier vos instructions relatives au sort que vous souhaitez réserver à vos données en cas de décès.</p>
      <p>Vous disposez enfin du droit de révoquer à tout moment le consentement que vous nous avez donné en vue de traiter vos données personnelles.</p>
      <p>L’ensemble de ces droits s’exerce auprès de la société FLYTAGGER, par courrier postal adressé au siège de ladite société (coordonnées à l’article 1) accompagné de la copie d’un titre d’identité comportant une signature, ou par courriel adressé à dpo@flytagger.com, également accompagné de la copie d’un titre d’identité comportant une signature</p>

      <h3 class="titleCGV">ARTICLE 6 – LOI APPLICABLE ET JURIDICTION COMPETENTE</h3>
      <p>Le présent règlement est soumis à la loi française exclusivement.</p>
      <p>Tout différend qui pourrait naître à l’occasion de ce jeu fera l’objet d’une tentative de règlement amiable avant d’être soumis au tribunal territorialement compétent.</p>
    </div>

    <div>
      <mat-checkbox *ngIf="pdfEtape == 1" [(ngModel)]="accepteRGPD">Je reconnais avoir lu et j'accepte les conditions générales et le règlement du jeu.</mat-checkbox>
    </div>

    <div class="flex align-items-center gap-2">
      <p-button *ngIf="pdfEtape == 1" label="Retour" (click)="pdfEtape = 0" styleClass="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10" class="w-full"  />
      <p-button [label]="pdfEtape == 0 ? 'Suivant' : 'Accepter'" [disabled]="!accepteRGPD  && pdfEtape != 0" (click)="saveDialog()" styleClass="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10" class="w-full"  />
    </div>
  </div>
</p-dialog>

