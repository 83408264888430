<div class="globalContainer">
  <div class="parentDiv">

    <div [class]="enableErreur">
      <div class="leftContentAlerte">
        <div class="animate__animated animate__tada animate__repeat animate__infinite">
          <mat-icon class="example-tab-icon" style="color: white;font-size: xx-large">warning_amber</mat-icon>
        </div>
        <div style="color: white">
          Alert
        </div>
      </div>
      <div>
        {{messageErreur}}
      </div>
    </div>



    <!--<div class="scrollEnseigne">
          <div class="ssScrollEnseigne">
            <div class="linkCommentaireDiv" [ngClass]="indexClicked == idx ? 'clickedClass' : ''" *ngFor="let ens of lstEnseigne; let idx = index;" (click)="enseigneClick(ens, idx)">
              <img *ngIf="ens.Image" class="imgEnseigne" [src]="ens.Image" />
              <div class="infoEnseigne">
                <p>{{ens.Nom}}</p>
                <p>{{ens.Adresse}}</p>
                <p>{{ens.CodePostal}}</p>
                <p>{{ens.Ville}}</p>
              </div>
            </div>
          </div>
        </div>-->

  <div class="parentdetailEnseigne">
    <h1 class="title">Personnalisation du site standard</h1>

    <p-steps [model]="items" [(activeIndex)]="activeIndex" styleClass="custom-stepper" style="width: 100%;">
    </p-steps>

    <div *ngIf="activeIndex == 0" class="content">
      <div class="menuDiv">
        <div appearance="fill" style="font-size: 16px; margin:1em; display: flex; flex-direction: row; justify-content: center; align-items: center;">
          <div>
            <input class="file-field" #imageInput type="file" name="file" id="file" (change)="onFileChange($event, 0)" accept=".jpg,.jpeg,.png,.bmp" />
            <label for="file" class="file-label">
              <figure>
                <i class="pi pi-file"></i>
              </figure>
              <span>Choisir une image&hellip;</span>
            </label>
          </div>
          <img *ngIf="detailEnseigne?.ens?.IdPersoSiteAnnonceurNavigation?.Menu" class="imgClass" [src]="detailEnseigne.ens.IdPersoSiteAnnonceurNavigation.Menu" />
        </div>

        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title>Conseil</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p class="textConseil">{{conseilImage.menu}}</p>
          </mat-card-content>
        </mat-card>

      </div>
    </div>

    <div *ngIf="activeIndex == 1" class="content">
      <div class="menuDiv">
        <div appearance="fill" style="font-size: 16px; margin:1em; display: flex; flex-direction: row; justify-content: center; align-items: center;">
          <div>
            <input class="file-field" name="file2" id="file2" #imageInput type="file" (change)="onFileChange($event, 1)" accept=".jpg,.jpeg,.png" />
            <label for="file2" class="file-label">
              <figure>
                <i class="fa fa-upload"></i>
              </figure>
              <span>Choisir une image&hellip;</span>
            </label>
          </div>

          <img *ngIf="detailEnseigne?.ens?.IdPersoSiteAnnonceurNavigation?.Image1" class="imgClass" [src]="detailEnseigne.ens.IdPersoSiteAnnonceurNavigation.Image1" />
        </div>

        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title>Conseil</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p class="textConseil">{{conseilImage.image1}}</p>
          </mat-card-content>
        </mat-card>

      </div>
    </div>

    <div *ngIf="activeIndex == 2" class="content">
      <div class="menuDiv">
        <div appearance="fill" style="font-size: 16px; margin: 1em; display: flex; flex-direction: row; justify-content: center; align-items: center;">
          <div>
            <input class="file-field" name="file3" id="file3" #imageInput type="file" (change)="onFileChange($event, 2)" accept=".jpg,.jpeg,.png" />
            <label for="file3" class="file-label">
              <figure>
                <i class="fa fa-upload"></i>
              </figure>
              <span>Choisir une image&hellip;</span>
            </label>
          </div>

          <img *ngIf="detailEnseigne?.ens?.IdPersoSiteAnnonceurNavigation?.Image2" class="imgClass" [src]="detailEnseigne.ens.IdPersoSiteAnnonceurNavigation.Image2" />
        </div>

        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title>Conseil</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p class="textConseil">{{conseilImage.image2}}</p>
          </mat-card-content>
        </mat-card>

      </div>
    </div>

    <div *ngIf="activeIndex == 3" class="content">
      <div class="menuDiv">
        <div appearance="fill" style="font-size: 16px; margin: 1em; display: flex; flex-direction: row; justify-content: center; align-items: center;">
          <div>
            <input class="file-field" name="file4" id="file4" #imageInput type="file" (change)="onFileChange($event, 3)" accept=".jpg,.jpeg,.png" />
            <label for="file4" class="file-label">
              <figure>
                <i class="fa fa-upload"></i>
              </figure>
              <span>Choisir une image&hellip;</span>
            </label>
          </div>

          <img *ngIf="detailEnseigne?.ens?.IdPersoSiteAnnonceurNavigation?.Image3" class="imgClass" [src]="detailEnseigne.ens.IdPersoSiteAnnonceurNavigation.Image3" />
        </div>

        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title>Conseil</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p class="textConseil">{{conseilImage.image3}}</p>
          </mat-card-content>
        </mat-card>

      </div>
    </div>

    <div *ngIf="activeIndex == 4" class="content">
      <div class="menuDiv">
        <div appearance="fill" style="font-size: 16px; margin: 1em; display: flex; flex-direction: row; justify-content: center; align-items: center;">
          <div>
            <input class="file-field" name="file5" id="file5" #imageInput type="file" (change)="onFileChange($event, 4)" accept=".jpg,.jpeg,.png" />
            <label for="file5" class="file-label">
              <figure>
                <i class="fa fa-upload"></i>
              </figure>
              <span>Choisir une image&hellip;</span>
            </label>
          </div>

          <img *ngIf="detailEnseigne?.ens?.IdPersoSiteAnnonceurNavigation?.Image4" class="imgClass" [src]="detailEnseigne.ens.IdPersoSiteAnnonceurNavigation.Image4" style="" />
        </div>

        <mat-card class="example-card">
          <mat-card-header>
            <mat-card-title>Conseil</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p class="textConseil">{{conseilImage.image4}}</p>
          </mat-card-content>
        </mat-card>

      </div>
    </div>

    <!-- ... continuez avec les autres étapes -->

    <div class="flex flex-row justify-content-around align-items-center" style="width: 20%">
      <p-button label="Retour"  severity="secondary" (click)="prevStep()" *ngIf="activeIndex != 0 " class="p-button-secondary"></p-button>
      <p-button label="Suivant"  (click)="nextStep()" *ngIf="activeIndex != items.length - 1"></p-button>
      <p-button label="Enregistrer" severity="success" (click)="save()" *ngIf="activeIndex == items.length - 1" class="p-button-success"></p-button>
    </div>
    


    <!--<mat-stepper #stepper class="matStepper">
    <mat-step state="image">
      <ng-template matStepLabel class="spanCategorie">Flyer commercial</ng-template>

      <div>
        <button mat-button matStepperNext (click)="onStepChange($event)">Suivant</button>
      </div>
    </mat-step>
    <mat-step state="photo_library">
      <ng-template matStepLabel class="spanImage">Image de présentation 1</ng-template>

      <div>
        <button mat-button matStepperPrevious>Retour</button>
        <button mat-button matStepperNext (click)="onStepChange($event)">Suivant</button>
      </div>
    </mat-step>

    <mat-step state="photo_library">
      <ng-template matStepLabel class="spanImage">Image de présentation 2</ng-template>

      <div>
        <button mat-button matStepperPrevious>Retour</button>
        <button mat-button matStepperNext (click)="onStepChange($event)">Suivant</button>
      </div>
    </mat-step>

    <mat-step state="photo_library">
      <ng-template matStepLabel class="spanImage">Image de présentation 3</ng-template>

      <div>
        <button mat-button matStepperPrevious>Retour</button>
        <button mat-button matStepperNext (click)="onStepChange($event)">Suivant</button>
      </div>
    </mat-step>

    <mat-step state="photo_library">
      <ng-template matStepLabel class="spanImage">Image de présentation 4</ng-template>

      <div>
        <button mat-button matStepperPrevious>Retour</button>
        <button type="button" class="btn btn-primary" (click)="save(); onStepChange($event)">Enregistrer</button>
      </div>
    </mat-step>

    <ng-template matStepperIcon="phone">
      <mat-icon>image</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="photo_library">
      <mat-icon>perm_media</mat-icon>
    </ng-template>

  </mat-stepper>-->

  </div>

  </div>
</div>
