<ul class="layout-menu">
  <ng-container *ngFor="let item of model; let i = index;">
    <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true" ></li>
    <li *ngIf="item.separator" class="menu-separator"></li>
  </ng-container>
  <!-- <li>
      <a href="https://www.primefaces.org/primeblocks-ng/#/">
          <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'banner-primeblocks' : 'banner-primeblocks-dark'}}.png" alt="Prime Blocks" class="w-full mt-3"/>
      </a>
  </li> -->
  <li></li>
  <li>
    <div class="flex flex-column justify-content-around align-content-center" style="margin-top: 2em; margin-bottom: 1em; ">
      <img class="imgFlyStore" src="assets/image/flytaggerPro.png" />
      <span style="margin-bottom: 1em; margin-top: 1em;">Gérez vos clients plus facilement en téléchargeant l’application Flytagger Pro</span>
      <div class="flex flex-row justify-content-between align-content-center">
        <div class="store" (click)="openStore('ios')">
          <img class="imgStore" src="assets/icon/apple-icon.png" />
          <div>
            Apple Store
          </div>
        </div>

        <div class="store" (click)="openStore('android')">
          <img class="imgStore" src="assets/icon/play-store.png" />
          <div>
            Google Play
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>


