import { environment } from '../../environments/environment';

export class urlConfig {

  constructor() { }

  static getUrl(controller: string) {
    let url = environment.backendServer;

    if (controller.toLowerCase() == "login") {
      url += "api/InteractionUser/Login";
    }
    else if (controller.toLowerCase() == "creeruser") {
          url += "api/InteractionUser/CreerUser";
    }
    else if (controller.toLowerCase() == "interaction") {
      url += "api/InteractionUser/";
    }
    else {
      url += "api/Command/";
      switch (controller.toLowerCase()) {
        //case "command":
        //  url += "api/Command/";
        //  break;
        case "getimagefileenseigne":
          url += "getImageFileEnseigne/";
          break;
        case "pub":
          url += "PostPub";
          break;
        case "campagne":
          url += "setCampagne";
          break;
        case "enseigne":
          url += "getEnseigne";
          break;
        case "lot":
          url += "getListLot";
          break;
        case "putlot":
          url += "PutLot";
          break;
        case "putlotfidelite":
          url += "PutLotFidelite";
          break;
        case "setlotjeux":
          url += "setLotJeux";
          break;
        case "deletelotjeux":
          url += "deleteLotJeux";
          break;
        case "deleteenseigne":
          url += "deleteEnseigne";
          break;
        case "addenseigne":
          url += "addEnseigne";
          break;
        case "validegainuser":
          url += "valideGainUser";
          break;
        case "getfideliteforannonceur":
          url += "getFideliteForAnnonceur";
          break;
        case "getlotfideliteforenseigne":
          url += "getLotFideliteForEnseigne";
          break;
        case "getfideliteforannonceurwithgain":
          url += "getFideliteForAnnonceurWithGain";
          break;
        case "savelot":
          url += "PostLotFidelite";
          break;
        case "deletelot":
          url += "DeleteLotFidelite";
          break;
        case "addpointfidelite":
          url += "addPointFidelite";
          break;
        case "addpointfidelitecategorie":
          url += "addPointFideliteCategorie";
          break;
        case "addfideliteenseigne":
          url += "addFideliteEnseigne";
          break;
        case "getmessageenseigne":
          url += "getMessageEnseigne";
          break;
        case "setmessageenseigne":
          url += "setMessageEnseigne";
          break;
        case "putenseigne":
          url += "PutEnseigne";
          break;
        case "getenseigneforsiteannonceur":
          url += "getEnseigneForSiteAnnonceur";
          break;
        case "gettypefidelite":
          url += "getTypeFidelite";
          break;
        case "sendcommunication":
          url += "sendCommunication";
          break;
        case "getvendeur":
          url += "getSeller";
          break;
        case "deleteseller":
          url += "DeleteSeller";
          break;
        case "setvendeur":
          url += "setVendeur";
          break;
        case "getfeedback":
          url += "getFeedBack";
          break;
      }
    }
    

    return url;
  }
}
