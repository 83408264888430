<div class="menu">
  <div class="title">Enseignes</div>
  <div>
    <div class="scrollEnseigne" id="scrollEnseigne">

      <h4 *ngIf="lstEnseigne && lstEnseigne.length > 1" >Enseigne Selectionnée</h4>
      <div style="background-color: transparent; padding: 1em; padding-top: 0em; ">
        <div class="clickedClass">
          <img *ngIf="enseigneSelected && enseigneSelected.Image" class="imgEnseigne" [src]="enseigneSelected.Image" />
          <div class="infoEnseigne">
            <p style="margin: 0;" *ngIf="enseigneSelected && enseigneSelected.Nom">{{enseigneSelected.Nom}}</p>
            <p style="margin: 0;" *ngIf="enseigneSelected && enseigneSelected.Adresse">{{enseigneSelected.Adresse}}</p>
            <p style="margin: 0;" *ngIf="enseigneSelected && enseigneSelected.CodePostal">{{enseigneSelected.CodePostal}}</p>
            <p style="margin: 0;" *ngIf="enseigneSelected && enseigneSelected.Ville">{{enseigneSelected.Ville}}</p>
          </div>
        </div>
      </div>

      <div class="contentListEnseigne" *ngIf="lstEnseigne && lstEnseigne.length > 1">
        <div class="ssScrollEnseigne">
          <div class="linkCommentaireDiv" *ngFor="let ens of lstEnseigne; let idx = index;" (click)="enseigneClick(ens)">
            <img *ngIf="ens && ens.Image" class="imgEnseigne" [src]="ens.Image" />
            <div class="infoEnseigne" *ngIf="ens">
              <p style="margin: 0;" >{{ens.Nom}}</p>
              <p style="margin: 0;" >{{ens.Adresse}}</p>
              <p style="margin: 0;" >{{ens.CodePostal}}</p>
              <p style="margin: 0;" >{{ens.Ville}}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
