import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from "./service/app.layout.service";
import { environment } from '../../environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { AppComponent } from '../app.component';
import { DialogComponent } from '../dialog/dialog.component';
import { LoginComponent } from '../login/login.component';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { urlConfig } from '../service/urlConfig.service';
import { BarcodeFormat } from '@zxing/library';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { DialogAvertissementComponent } from '../dialog-avertissement/dialog-avertissement.component';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;
  @ViewChild('topbarmenu') menu!: ElementRef;
  @ViewChild('scanner') scanner: ZXingScannerComponent;

  public qrCodeDownloadLink: SafeUrl = "";
  allowedFormats = [BarcodeFormat.QR_CODE];

  env = environment;

  isTooltipVisible = {
    qrcode: false,
    camera: false
  };

  isOutsideClicked = false;

  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });

  public static savedEnseigneSelected: any;

  lstEnseigne: any;

  expand = false;

  enseigneSelected: any;

  contrat = { siteWeb: false, animationClientele: false, communication: false };
  public static contratStatic = { siteWeb: false, animationClientele: false, communication: false };

  clickUp = false;

  obMoveDiv: Subscription = new Subscription();

  qrCodeEnseigne = "";
  imageSrc: string = "../../assets/image/iconFlytagger2.png";
  imageHeight: 500;
  imageWidth: 500;
  margin: 4;

  valideGain = false;
  enableCam = true;
  qrResultString: string;
  qrCodeREsultJson: any;

  hasCameras = false;
  hasPermission: boolean;
  idXselectedDevice = 0;

  availableDevices: MediaDeviceInfo[];
  selectedDevice: MediaDeviceInfo;

  arrayNumber = Array.from({ length: 1000 }, (_, i) => {
    return { name: i + 1, code: i + 1 };
  });


  addPointFidelite = { name: 0, code: 0 };
  indexClicked = 0;

  type = 0;
  lotFidelite: any;
  choixLotFidelite: any;
  choixLotFideliteDropDown: any;

  lotFideliteDropDown = [];

  constructor(public layoutService: LayoutService, private http: HttpClient, private dialog: MatDialog, private app: AppComponent, private sanitizer: DomSanitizer) {
    this.getListEnseigne();

    this.app.ensSelected.subscribe((ens: any) => {
      this.enseigneSelected = ens;
      AppTopBarComponent.savedEnseigneSelected = ens;
    });

    this.app.reloadEnseigne.subscribe((val: any) => {
      if (val) {
        this.getListEnseigne();
      }
    });

  }

  ngOnInit() {
    
  }

  onMenuToggle() {
    this.isOutsideClicked = !this.isOutsideClicked;
  }

  onChangeEnseigne() {
    this.enseigneClick(this.enseigneSelected);
  }

  enseigneClick(ens) {
    this.enseigneSelected = ens;
    this.app.ensSelected.next(ens);
    this.type = this.enseigneSelected.IdTypeFideite;

    AppTopBarComponent.contratStatic = {
      siteWeb: this.enseigneSelected.IdContratsNavigation.SiteWeb,
      animationClientele: this.enseigneSelected.IdContratsNavigation.AnimationClientele,
      communication: this.enseigneSelected.IdContratsNavigation.Communication
    };

    this.contrat = {
      siteWeb: this.enseigneSelected.IdContratsNavigation.SiteWeb,
      animationClientele: this.enseigneSelected.IdContratsNavigation.AnimationClientele,
      communication: this.enseigneSelected.IdContratsNavigation.Communication
    };

    if (this.enseigneSelected) {
      this.qrCodeEnseigne = this.env.siteStandard + this.enseigneSelected.Ville.replaceAll(' ', '%20') + "/" + this.enseigneSelected.Type + "/" + this.enseigneSelected.Nom.replaceAll(' ', '%20') + "/" + this.enseigneSelected.Id;
    }

  }

  getListEnseigne() {
    this.http.post(urlConfig.getUrl("enseigne"), NavMenuComponent.usrConnected).subscribe((res: any) => {
      this.lstEnseigne = res.data;
      console.log("this.lstEnseigne", this.lstEnseigne);
      this.enseigneClick(this.lstEnseigne[0]);

    });
  }

  moveDiv() {
    var divParent = document.getElementById("scrollEnseigne");
    this.obMoveDiv = fromEvent(document.body, 'mousemove').subscribe((e: MouseEvent) => {
      divParent.style.left = e.pageX + "px";
      divParent.style.top = e.pageY + "px";

    });

    fromEvent(document.body, 'click').subscribe((e: MouseEvent) => {
      this.obMoveDiv.unsubscribe();
    });

  }

  toggleTooltip(type: 'qrcode' | 'camera'): void {
    this.desableCam();
    // Si la tooltip demandée est déjà ouverte, la fermez
    if (this.isTooltipVisible[type]) {
      this.isTooltipVisible[type] = false;
      return;  // Sortez de la fonction tôt
    }

    // Fermez toutes les tooltips
    for (let key in this.isTooltipVisible) {
      this.isTooltipVisible[key] = false;
    }

    // Ouvrez la tooltip demandée
    this.isTooltipVisible[type] = !this.isTooltipVisible[type];
  }



  desableCam() {
    this.enableCam = false;
    this.valideGain = false;
    //this.scanner.restart();
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    this.qrCodeREsultJson = JSON.parse(this.qrResultString);

    if (this.type == 2) {
      this.loadFidelite();
    }

    this.enableCam = false;
  }

  handleQrCodeResult(resultString: string) {
    this.qrResultString = resultString;
    this.enableCam = false;
  }

  onDeviceSelectChange(selectedValue: string) {
    this.selectedDevice = this.availableDevices[this.idXselectedDevice];
  }

  camerasFoundHandler(event) {
    this.availableDevices = event;
  }

  cameraFound($event) {
    this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      this.hasCameras = true;

      this.availableDevices = devices;

    });

    this.scanner.camerasNotFound.subscribe((devices: MediaDeviceInfo[]) => {
      console.error('An error has occurred when trying to enumerate your video-stream-enabled devices.');
    });

    this.scanner.permissionResponse.subscribe((answer: boolean) => {
      this.hasPermission = answer;
    });
  }

  validerCode() {

    var str = this.qrResultString.trim();
    var parts = [];
    parts.push(str.slice(0, 8));
    parts.push(str.slice(8, 12));
    parts.push(str.slice(12, 16));
    parts.push(str.slice(16, 20));
    parts.push(str.slice(20, 32));

    var GUID = parts.join('-');

    this.dialog.open(DialogComponent, {
      data: {
        title: "Validation du gain",
        message: 'Validation en cours !!!',
        enableLoader: true,
        congrat: false
      }
    });

    this.http.post(urlConfig.getUrl("valideGainUser"), { jgu: { Code: this.qrCodeREsultJson.code } }).subscribe((res: any) => {

      this.dialog.closeAll();

      if (res.success) {
        this.clearResult();
        this.desableCam();
      }

    });

  }

  validerPointFidelite() {

    var txtPoint = this.addPointFidelite.code > 1 ? this.addPointFidelite.code + ' points' : this.addPointFidelite.code + ' point';
    let dialogRef = this.dialog.open(DialogAvertissementComponent, {
      data: {
        title: "Attention !!!",
        message: 'Validation des points à ajouter',
        message2: 'Etes-vous sur d\'ajouter : ',
        message3: txtPoint,
        message4: 'Votre choix sera définitif',
        displayImage: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.valide) {


        this.dialog.open(DialogComponent, {
          data: {
            title: "Ajout point fidelite",
            message: 'Ajout en cours !!!',
            enableLoader: true,
            congrat: false
          }
        });

        if (this.type == 2) {
          this.choixLotFidelite = this.lotFidelite.find(o => {
            return o.Id == this.choixLotFideliteDropDown.code;
          });
          let pointUser = { IdUser: this.qrCodeREsultJson.id, IdEnseigne: this.enseigneSelected.Id, Point: this.addPointFidelite.code, IdLotFidelite: this.choixLotFidelite.Id };

          this.http.post(urlConfig.getUrl("addPointFideliteCategorie"), pointUser).subscribe((res: any) => {
            this.dialog.closeAll();
            if (res.success) {
              this.addPointFidelite.code = 0;
              this.clearResult();
              this.desableCam();

              for (let key in this.isTooltipVisible) {
                this.isTooltipVisible[key] = false;
              }

            }
          });
        }
        else {
          let pointUser = { idUser: this.qrCodeREsultJson.id, idEnseigne: this.enseigneSelected.Id, nbPoint: this.addPointFidelite.code };


          this.http.post(urlConfig.getUrl("addpointfidelite"), pointUser).subscribe((res: any) => {
            this.dialog.closeAll();
            if (res.success) {
              this.clearResult();
              this.desableCam();

              for (let key in this.isTooltipVisible) {
                this.isTooltipVisible[key] = false;
              }

            }
          });
        }


      }

    });

  }

  clearResult(): void {
    this.qrResultString = null;
    this.enableCam = true;
  }

  loadFidelite() {
    //this.dialog.open(DialogComponent, {
    //  data: {
    //    title: "Chargement des lots",
    //    message: 'Chargement en cours !!!',
    //    enableLoader: true,
    //    congrat: false
    //  }
    //});

    this.http.post(urlConfig.getUrl("getlotfideliteforenseigne"), { "Id": this.enseigneSelected.Id }).subscribe((res: any) => {

      if (res.success) {
        this.lotFidelite = res.data;

        this.lotFidelite.forEach(o => {
          this.lotFideliteDropDown.push({name: o.Lot, code: o.Id});
        });

        console.log("res", res);
        console.log("this.lotFideliteDropDown", this.lotFideliteDropDown);
        //this.dialog.closeAll();
      }
    });
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  downloadQRCode() {
    const qrcode = document.querySelector('qrcode canvas') as HTMLCanvasElement;
    console.log("qrcode", qrcode);
    if (qrcode) {
      const imageUrl = qrcode.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = 'qr-code.png';
      link.click();
    }
  }

  ngOnDestroy() {
    this.desableCam();
  }

}
