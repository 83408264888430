<div class="globalContainer">
  <div class="d-flex flex-column align-items-center justify-content-around parentDiv">

    <!--<div class="scrollEnseigne">
          <div class="ssScrollEnseigne">
            <div class="linkCommentaireDiv" [ngClass]="indexClicked == idx ? 'clickedClass' : ''" *ngFor="let ens of lstEnseigne; let idx = index;" (click)="enseigneClick(ens, idx)">
              <img *ngIf="ens.Image" class="imgEnseigne" [src]="ens.Image" />
              <div class="infoEnseigne">
                <p>{{ens.Nom}}</p>
                <p>{{ens.Adresse}}</p>
                <p>{{ens.CodePostal}}</p>
                <p>{{ens.Ville}}</p>
              </div>
            </div>
          </div>
        </div>-->
    <h1 class="title">Communication</h1>

    <div *ngIf="contrat.communication" class="boxMessage">
      <mat-tab-group style="width: 100%;">
        <mat-tab label="Annonce application ">
          
        </mat-tab>
        <mat-tab label="Communication clientèle">
          

        </mat-tab>

      </mat-tab-group>





    </div>

    <div *ngIf="!contrat.communication" class="notGame">
      <div class="titleNotGame">Soyez visibles auprès de vos clients.</div>
      <div class="textNotGame">
        L’envoi de messages sur l’activité de votre commerce, permet une communication aux plus proches de vos clients par SMS, email, notification.
        Votre choix d’envoyer un message ciblé apportera du trafic client les jours de faible affluence.
      </div>

      <div class="textNotGame">
        Ce service fait partie de l’offre communication.
      </div>

      <div class="textNotGame">
        Pour bénéficier de cette offre ou visualiser une démo contacter nous au 05.61.446.449 ou à contact@flytagger.com
      </div>
    </div>

  </div>
</div>
