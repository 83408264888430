import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { DialogComponent } from '../dialog/dialog.component';
import { LoginComponent } from '../login/login.component';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { urlConfig } from '../service/urlConfig.service';
import { environment } from '../../environments/environment';
import { AppComponent } from '../app.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { data0, data1, data2, data3, areaData } from './datasource';
import { AcceptConditionsModalComponent } from '../accept-conditions-modal/accept-conditions-modal.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';

/*import { Browser } from '@syncfusion/ej2-base';*/

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  contrat = { siteWeb: false, animationClientele: false, communication: false };

  public static lastEnseigne: any;

  env = environment;

  enseigneSelected: any;
  indexClicked = 0;

  feeBack: any;

  chartOptions: any = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top'
      }
    }
  };

  dataClientsParJour: any;
  dataClientsParSemaine: any;
  dataClientsParMois: any;
  dataGainsParJour: any;
  dataGainsConsommesParJour: any;
  dataPointsParJour: any;
  dataClientsAujourdhuiParHeure: any;
  dataPointsAttribuesAujourdhuiParHeure: any;
  dataGainsConsommesAujourdhuiParHeure: any;

  isCGVChecked = false;
  showModalCGV = false;
  pdfUrl = ["assets/pdf/cgv.pdf","assets/pdf/rdj.pdf"];
  pdfEtape = 0;
  accepteRGPD = false;

  constructor(private http: HttpClient,private sanitizer: DomSanitizer, private router: Router, private app: AppComponent,public dialogService: DialogService, private confirmationService: ConfirmationService, private messageService: MessageService) {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }
  }

  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit(): void {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }
  }

  ngAfterViewInit() {
    this.app.ensSelected.subscribe((ens: any) => {
      if(!this.estVide(ens)){
        HomeComponent.lastEnseigne = ens;
        this.enseigneClick(ens);
      }
    });

  }

  estVide(obj) {
    return Object.keys(obj).length === 0;
  }
  
  getFeedBack() {
    this.http.post(urlConfig.getUrl("getfeedback"), this.enseigneSelected).subscribe((res: any) => {
      if (res.success) {
        this.feeBack = res.data;
        this.initializeChartData();        
      }
    });
  }

  enseigneClick(ens) {
    this.enseigneSelected = ens;
    this.getFeedBack();

    console.log("this.enseigneSelected",this.enseigneSelected);
    if (!(this.enseigneSelected.AccepteCgv && this.enseigneSelected.AccepteCuj) && NavMenuComponent.connected) {
      this.showModalCGV = true;
    }
    else {
      this.showModalCGV = false;
    }

    this.contrat = {
      siteWeb: this.enseigneSelected?.IdContratsNavigation?.SiteWeb,
      animationClientele: this.enseigneSelected?.IdContratsNavigation?.AnimationClientele,
      communication: this.enseigneSelected?.IdContratsNavigation?.Communication
    };
  }

  saveDialog(){
    if(this.pdfEtape ==0){
      this.pdfEtape = 1;
    }
    else if(this.accepteRGPD) {
      this.enseigneSelected.AccepteCgv = true;
      this.enseigneSelected.AccepteCuj = true;

      this.http.post(urlConfig.getUrl("PutEnseigne"), this.enseigneSelected).subscribe((res: any) => {
        if(res.success){
          this.pdfEtape = 0;
          this.accepteRGPD = false;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Acceptation enregistrées avec succès.' });
          this.enseigneClick(res.data);
        }
      });
    }
  }

  initializeChartData() {
    if(this.feeBack?.ClientsParJour != null){
      const hoursLabels = Array.from({length: 24}, (_, i) => `${i}h`);
      const today = new Date();

      const daysLabels = Array.from({ length: this.feeBack.ClientsParJour.length }, (_, i) => {
        const dayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
        return `${dayDate.getDate().toString().padStart(2, '0')}/${(dayDate.getMonth() + 1).toString().padStart(2, '0')}`;
      }).reverse();

      const weeksLabels = Array.from({ length: this.feeBack.ClientsParSemaine.length }, (_, i) => {
        const endOfWeek = new Date(new Date().setDate(new Date().getDate() - (i * 7)));
        const startOfWeek = new Date(new Date(endOfWeek).setDate(endOfWeek.getDate() - 6));

        return `${startOfWeek.getDate().toString().padStart(2, '0')}-${endOfWeek.getDate().toString().padStart(2, '0')}/${(endOfWeek.getMonth() + 1).toString().padStart(2, '0')}`;
      }).reverse();

      const monthsLabels = Array.from({ length: 12 }, (_, i) => {
        const date = new Date();
        date.setMonth(date.getMonth() - (11 - i), 1);
        return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
      });


      this.dataClientsParJour = this.createLineChartData(daysLabels, this.feeBack.ClientsParJour, 'Nouveaux clients Sur 2 semaines', '#42A5F5');
      this.dataClientsParSemaine = this.createLineChartData(weeksLabels, this.feeBack.ClientsParSemaine, 'Nouveaux clients Sur 3 mois', '#66BB6A');
      this.dataClientsParMois = this.createLineChartData(monthsLabels, this.feeBack.ClientsParMois, 'Nouveaux clients Sur 12 mois', '#FFA726');
      this.dataGainsParJour = this.createLineChartData(daysLabels, this.feeBack.GainsParJour, 'Lots gagnés sur 2 semaines', '#9CCC65');
      this.dataGainsConsommesParJour = this.createBarChartData(daysLabels, this.feeBack.GainsConsommesParJour, 'Lots retiré sur 2 semaines');
      this.dataPointsParJour = this.createPieChartData(daysLabels, this.feeBack.PointsParJour, 'Points ajoutés sur 2 semainess');
      this.dataClientsAujourdhuiParHeure = this.createBarChartData(hoursLabels, this.feeBack.ClientsAujourdhuiParHeure, 'Nouveaux clients');
      this.dataPointsAttribuesAujourdhuiParHeure = this.createBarChartData(hoursLabels, this.feeBack.PointsAttribuesAujourdhuiParHeure, 'Points attribués');
      this.dataGainsConsommesAujourdhuiParHeure = this.createBarChartData(hoursLabels, this.feeBack.GainsConsommesAujourdhuiParHeure, 'Lots retiré');
    }
    
  }

  createLineChartData(labels, data, label, borderColor) {
    return {
      labels,
      datasets: [
        {
          label,
          data,
          fill: false,
          borderColor,
          tension: 0.1
        }
      ],
      options: this.chartOptions
    };
  }

  createBarChartData(labels, data, label) {
    return {
      labels,
      datasets: [
        {
          label,
          backgroundColor: '#42A5F5',
          data
        }
      ],
      options: this.chartOptions
    };
  }

  createPieChartData(labels, data, label) {
    return {
      labels,
      datasets: [
        {
          label,
          backgroundColor: '#42A5F5',
          data
        }
      ],
      options: this.chartOptions
    };
  }

  sumArrayElements(array: number[]): number {
    if(!array) return 0;
    return array.reduce((a, b) => a + b, 0);
  }

}
