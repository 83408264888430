<div class="globalContainer">

  <div class="parentdiv" *ngIf="lotFidelite">

    <div class="flex flex-column justify-content-between align-items-center">

      <div class="flex flex-row justify-content-evenly align-items-center" style="margin-bottom: 1em">
        <span class="ui-message ui-messages-error" style="color: red; font-size: 14px; margin-bottom: 10px; background-color: white; border-radius: 16px; padding: 1em; border: 1px solid;">
          Information sur les conditions d'attribution des points qui apparaîtra sur la carte de fidélité du client. <br /> Votre programme de fidélité ne sera pas activé si vous ne renseignez pas cette information
        </span>
        <div class="flex flex-column align-items-center justify-content-between" style="margin-left: 1em;">
          <span>Condition de validation des gains :</span>
          <textarea rows="4" cols="50" pInputTextarea [(ngModel)]="condAtt"></textarea>
          <small style="color: lightslategray"><strong>Ex. : 1€ dépensé(s) = 1 point attribué.</strong></small>
          <p-button *ngIf="condAtt != enseigneSelected.ConditionAttribution" label="Enregistrer" [raised]="true" [rounded]="true" (click)="validAtt()"></p-button>
        </div>

      </div>

      <div *ngIf="enseigneSelected && contrat.animationClientele && categorieSelected" class="typeFideliteParent">
        <div [class]="enseigneSelected.IdTypeFideite ? 'typeFideliteValide' : 'obligatoire'">
          <div *ngIf="!enseigneSelected.IdTypeFideite" class="animate__animated animate__tada animate__repeat animate__infinite">
            <mat-icon class="example-tab-icon" style="color: red;">warning_amber</mat-icon>
          </div>
          {{ categorieSelected ? 'Type de carte' : 'Choix du type de carte :'}}
        </div>

        <div style="display: flex; flex-direction: row; justify-content: space-around; align-items: center;">
          <p-dropdown [options]="lstTypeFidelite" [(ngModel)]="enseigneSelected.IdTypeFideite" optionLabel="Nom" optionValue="Id" [showClear]="true" placeholder="Choisir un type de carte" [disabled]="true">
            <ng-template let-TypeFidelite pTemplate="typeFidelite">
              <div class="flex align-items-center">
                <span>{{ TypeFidelite.Nom }}</span>
              </div>
            </ng-template>
          </p-dropdown>
          <span class="material-icons informationCategorie" (click)="closeClick()" style="cursor: pointer;">
            info_outline
          </span>

        </div>
      </div>

      <div *ngIf="contrat.animationClientele" class="card" style="width: 100%; height: 32em; display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div *ngIf="!categorieSelected" class="avertissementMessage">Attention : Ce choix est définitif et ne pourra pas être modifié ultérieurement. Veuillez vous assurer de votre sélection avant de continuer.</div>
        <div *ngIf="!categorieSelected" class="typeFideliteParent">
          <div [class]="enseigneSelected.IdTypeFideite ? 'typeFideliteValide' : 'obligatoire'">
            <div *ngIf="!enseigneSelected.IdTypeFideite" class="animate__animated animate__tada animate__repeat animate__infinite">
              <mat-icon class="example-tab-icon" style="color: red;">warning_amber</mat-icon>
            </div>
            {{ categorieSelected ? 'Type de carte' : 'Choix du type de carte :'}}
          </div>

          <div style="display: flex; flex-direction: row; justify-content: space-around; align-items: center;">
            <p-dropdown [options]="lstTypeFidelite" [(ngModel)]="enseigneSelected.IdTypeFideite" optionLabel="Nom" optionValue="Id" [showClear]="true" placeholder="Choisir un type de carte">
              <ng-template let-TypeFidelite pTemplate="typeFidelite">
                <div class="flex align-items-center">
                  <span>{{ TypeFidelite.Nom }}</span>
                </div>
              </ng-template>
            </p-dropdown>
            <button pButton type="button" label="Ok" [class]=" categorieSelected ? 'buttonValidation disabledCategorie' : 'buttonValidation' " type="button" (click)="changeTypeFidelite()" [disabled]="categorieSelected"></button>

            <span class="material-icons informationCategorie" (click)="closeClick()" style="cursor: pointer;">
              info_outline
            </span>

          </div>
        </div>

        <p-table [value]="lotFidelite" [scrollable]="true" scrollHeight="29em" *ngIf="contrat.animationClientele && categorieSelected" editMode="row" dataKey="Lot">
          <ng-template pTemplate="header">
            <tr>
              <th>Récompense</th>
              <th>Nombre de points</th>
              <th>Valeur</th>
              <th>Minimum achat</th>
              <th>Action</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-lot let-editing="editing" let-idx="rowIndex">
            <tr [pEditableRow]="lot">
              <!-- Récompense -->
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="lot.Lot" readonly style="cursor: no-drop">
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{lot.Lot}}
                  </ng-template>
                </p-cellEditor>
              </td>

              <!-- Nombre de points -->
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-inputNumber [(ngModel)]="lot.NbPoint" [min]="0" [step]="1"></p-inputNumber>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{lot.NbPoint}}
                  </ng-template>
                </p-cellEditor>
              </td>

              <!-- Valeur -->
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-inputNumber [(ngModel)]="lot.Valeur" [min]="0" [step]="1"></p-inputNumber>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{lot.Valeur}}
                  </ng-template>
                </p-cellEditor>
              </td>

              <!-- Minimum achat -->
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-inputNumber [(ngModel)]="lot.MinAchat" [min]="0" [step]="1"></p-inputNumber>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{lot.MinAchat}}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td>
                <div class="flex align-items-center justify-content-center gap-2">
                  <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="idxEdit = idx; editLot(lot)" class="p-button-rounded p-button-text"></button>
                  <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="putLot()" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                  <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="initEditLot()" class="p-button-rounded p-button-text p-button-danger"></button>
                  <button *ngIf="!editing" pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" (click)="deleteLot(lot)"></button>
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="footer">
            <tr>
              <td>
                <input pInputText type="text" [(ngModel)]="addLot.Lot">
              </td>
              <td>
                <input pInputText type="text" [(ngModel)]="addLot.NbPoint">

              </td>
              <td>
                <input pInputText type="text" [(ngModel)]="addLot.Valeur">
              </td>
              <td>
                <input pInputText type="text" [(ngModel)]="addLot.MinAchat">
              </td>
              <td>
                <button pButton pRipple type="button" icon="pi pi-plus" (click)="saveLotFidelite()"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>


      </div>


      <div *ngIf="!contrat.animationClientele" class="notGame">
        <div class="titleNotGame">Créer un programme de fidélité à l’image de votre commerce.</div>
        <div class="textNotGame">
          Votre carte dématérialisée vous permet de fidéliser vos clients.
          Plus de carte perdue ou oubliée.
          Ce programme de fidélité fait gagner des points par achat, par passage, par magasin ou partagé sur le réseau.
        </div>

        <div class="textNotGame">
          Ce service fait partie de l’offre animation clientèle.
        </div>

        <div class="textNotGame">
          Pour bénéficier de cette offre ou visualiser une démo contacter nous au 05.61.446.449 ou à contact@flytagger.com
        </div>
      </div>



    </div>

  </div>
</div>

<p-dialog header="Communication" [(visible)]="showDialog" [style]="{width: '10vw'}">
  <i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
  <p>
    Envoie en cours !!!
  </p>
</p-dialog>


<p-dialog header="Différent type de fidélité" [(visible)]="showDialogType" [style]="{width: '60em'}">
  <div class="imgCat">
    <div class="textImg">
      <h4>Fidélité par points</h4>
      <p>Votre client pourra cumuler autant de points qu’il souhaite et les utiliser pour l’un des lots que vous aurez défini.</p>
      <img src="assets/image/parPoint.jpg" />
    </div>
    <div class="textImg">
      <h4>Fidélité par tampons</h4>
      <p>
        Votre client pourra cumuler des tampons sur les différentes cartes que vous aurez défini.
        Dès qu'une carte sera complète, il obtiendra le lot correspondant.
      </p>
      <img src="assets/image/parCategorie.jpg" />
    </div>
  </div>
</p-dialog>



