import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { AppComponent } from '../app.component';
import { DialogComponent } from '../dialog/dialog.component';
import { ListeEnseigneComponent } from '../liste-enseigne/liste-enseigne.component';
import { LoginComponent } from '../login/login.component';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { urlConfig } from '../service/urlConfig.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-site-standard',
  templateUrl: './site-standard.component.html',
  styleUrls: ['./site-standard.component.scss']
})
export class SiteStandardComponent implements OnInit {
  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });

  dernierMessage: any;
  addMessage = { IdEnseigne: 0, Text: "" };
  enseigneSelected: any;

  detailEnseigne: any;

  enableErreur = "errurDisplayNone";
  messageErreur = "";
 
  conseilImage = {
    menu: "Cette image doit représenter le menu de votre commerce. Il est important de choisir une image claire et nette qui montre les différents produits que vous proposez. Cette image permettra aux visiteurs de votre page de savoir ce que vous offrez avant même de venir chez vous.",
    image1: "Cette première image de présentation devrait être une photo de votre boutique ou restaurant prise de l'extérieur. Cela permettra aux visiteurs de votre page de se faire une idée de votre commerce avant même d'entrer. Essayez de choisir une image qui montre l'ambiance ou le style de votre commerce.",
    image2: "La deuxième image de présentation devrait montrer l'intérieur de votre commerce. Il est important de choisir une image qui met en avant votre décoration, vos produits ou votre ambiance. Cette image permettra aux visiteurs de se projeter dans votre commerce et de se faire une idée de l'expérience qu'ils pourront y vivre.",
    image3: "La troisième image de présentation devrait mettre en avant l'un de vos produits phares. Choisissez une image qui montre votre produit sous son meilleur jour et qui donne envie aux visiteurs de le goûter ou de l'acheter.",
    image4: "Enfin, la dernière image de présentation devrait montrer un moment de convivialité ou de partage dans votre commerce. Choisissez une image qui montre des clients heureux et satisfaits ou une ambiance chaleureuse. Cette image permettra aux visiteurs de votre page de se sentir les bienvenus et de les inciter à venir chez vous."
  };

  activeIndex: number = 0;
  items: MenuItem[];

  constructor(private http: HttpClient, private dialog: MatDialog, private router: Router, private app: AppComponent, private elRef: ElementRef, private messageService: MessageService) {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }

    this.app.ensSelected.subscribe((ens: any) => {
      this.enseigneSelected = ens;
      this.enseigneClick(ens);
    });

    this.items = [
      { label: 'Flyer commercial', icon: 'pi pi-image' },
      { label: 'Image de présentation 1', icon: 'pi pi-image' },
      { label: 'Image de présentation 2', icon: 'pi pi-image' },
      { label: 'Image de présentation 3', icon: 'pi pi-image' },
      { label: 'Image de présentation 4', icon: 'pi pi-image' }
    ];

    //this.app.ensSelected.next(ListeEnseigneComponent.savedEnseigneSelected);

    //this.getListEnseigne();
  }

  ngAfterViewInit() {
    // Trouvez tous les éléments avec la classe 'p-menuitem-link'
    const elements: NodeList = this.elRef.nativeElement.querySelectorAll('.p-menuitem-link');

    // Parcourez et modifiez le style de chaque élément
    elements.forEach((element: HTMLElement) => {
      element.style.padding = '0 10px'; // ajuster selon vos besoins
      element.style.width = 'auto'; // ou définir une largeur fixe si nécessaire
    });
  }

  prevStep() {
    this.activeIndex--;
  }

  nextStep() {
    this.activeIndex++;
  }

  //getListEnseigne() {
  //  this.dialog.open(DialogComponent, {
  //    data: {
  //      title: "Chargement des enseignes",
  //      message: 'Chargement en cours !!!',
  //      enableLoader: true,
  //      congrat: false
  //    }
  //  });

  //  this.http.post(urlConfig.getUrl("enseigne"), NavMenuComponent.usrConnected).subscribe((res: any) => {

  //    this.dialog.closeAll();

  //    this.lstEnseigne = res.data;
  //  });
  //}

  enseigneClick(ens) {
    this.enseigneSelected = ens;


    //this.dialog.open(DialogComponent, {
    //  data: {
    //    title: "Chargement détails enseigne",
    //    message: 'Chargement en cours !!!',
    //    enableLoader: true,
    //    congrat: false
    //  }
    //});

    this.http.post(urlConfig.getUrl("getenseigneforsiteannonceur"), this.enseigneSelected).subscribe((res: any) => {

      //this.dialog.closeAll();

      if (res && res.data) {
        this.detailEnseigne = res.data;
      }

      //detailEnseigne.ens.IdPersoSiteAnnonceurNavigation

      if (this.detailEnseigne && this.detailEnseigne.ens && this.detailEnseigne.ens.IdPersoSiteAnnonceurNavigation == null) {
        this.detailEnseigne.ens.IdPersoSiteAnnonceurNavigation = { AnimationTitre : true, CouleurTitre : "white", Menu : "", Video : "" , Image1 : "", Image2 : "", Image3 : "", Image4 : "" };
      }

    });

  }


  onFileChange(event: any, nmImg: number) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      var filesize: number = parseFloat( ((file.size / 1024) / 1024).toFixed(4) );

      console.log("file : ", file);

      var typeMatch = this.isImage(file.name);

      if (!typeMatch) {
        this.messageErreur = "Vous ne pouvez envoyer que des fichiers image au format PNG, JPG, JPEG, BMP de moins de 4Mo";
        this.enableErreur = "displayErreur";

        setTimeout(() => { this.enableErreur = "errurDisplayNone"; }, 5000);

      }
      else if (filesize > 4) {
        this.messageErreur = "Votre image dépasse 4Mo, veillez à télécharger iniquement des images de moins de 4Mo";
        this.enableErreur = "displayErreur";

        setTimeout(() => { this.enableErreur = "errurDisplayNone"; }, 5000);

      }
      else {
        reader.onload = () => {

          switch (nmImg) {
            case 0:
              this.detailEnseigne.ens.IdPersoSiteAnnonceurNavigation.Menu = reader.result as string;
              break;
            case 1:
              this.detailEnseigne.ens.IdPersoSiteAnnonceurNavigation.Image1 = reader.result as string;
              break;
            case 2:
              this.detailEnseigne.ens.IdPersoSiteAnnonceurNavigation.Image2 = reader.result as string;
              break;
            case 3:
              this.detailEnseigne.ens.IdPersoSiteAnnonceurNavigation.Image3 = reader.result as string;
              break;
            case 4:
              this.detailEnseigne.ens.IdPersoSiteAnnonceurNavigation.Image4 = reader.result as string;
              break;
          }
        };
      }
      

    }
  }


  getExtension(filename) {
    var parts = filename.split('.');
    console.log("extension : ", parts[parts.length - 1]);
    return parts[parts.length - 1];
  }

  isImage(filename) {
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'bmp':
      case 'png':
        return true;
    }
    return false;
  }


  save() {
    //this.dialog.open(DialogComponent, {
    //  data: {
    //    title: "Sauvegarde détails enseigne",
    //    message: 'Sauvegarde en cours !!!',
    //    enableLoader: true,
    //    congrat: false
    //  }
    //});

    this.http.post(urlConfig.getUrl("putenseigne"), this.detailEnseigne.ens).subscribe((res: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Images sauvegardées avec succès.' });
      //this.dialog.closeAll();
    });


  }



}
