import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';

//import * as L from 'leaflet';
//import * as LCampagne from 'leaflet';

import { LoginComponent } from '../login/login.component';
import { Router } from '@angular/router';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { urlConfig } from '../service/urlConfig.service';

//const provider = new OpenStreetMapProvider();
//const providerCampagne = new OpenStreetMapProvider();


//const searchControl = new GeoSearchControl({
//  provider: provider,
//  style: 'bar',
//  animateZoom: true,                                  // optional: true|false  - default true
//  autoClose: true,
//  showMarker: true,                                   // optional: true|false  - default true
//  showPopup: false,
//  searchLabel: 'Saisissez une adresse'
//});

//const searchCampagneControl = new GeoSearchControl({
//  provider: providerCampagne,
//  style: 'bar',
//  animateZoom: true,                                  // optional: true|false  - default true
//  autoClose: true,
//  showMarker: true,                                   // optional: true|false  - default true
//  showPopup: false,
//  searchLabel: 'Saisissez une adresse'
//});

@Component({
  selector: 'app-creation-campagne',
  templateUrl: './creation-campagne.component.html',
  styleUrls: ['./creation-campagne.component.scss']
})
export class CreationCampagneComponent implements OnInit {

  //urlPostPub: string = "https://207.180.246.59:57429/api/Command/PostPub";
  //urlPostCampagne: string = "https://207.180.246.59:57429/api/Command/setCampagne";
  //urlLogin: string = "https://207.180.246.59:57429/Account/Login";

  //urlPostPub: string = "https://localhost:5005/api/Command/PostPub";
  //urlLogin: string = "https://localhost:5005/Account/Login";
  //urlPostCampagne: string = "https://localhost:5005/api/Command/setCampagne";

  campagne = {
    nom: "",
    coordonnees: {
      Longitude: 0,
      Latitude: 0
    },
    Pub: {
      Titre: "",
      Body: "",
      ImageData2: "",
      Distance: 3,
      Nom: ""
    },
    budget: {
      montant: 0,
      coutParVue: 0,
      nombre: 0,
      capping: 0
    },
    date: {
      debut: "",
      fin: "",
      heureDebut: "",
      heureFin: ""
    },
    filtre: {

    }
  };

  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  fourthFormGroup: UntypedFormGroup;
  fithFormGroup: UntypedFormGroup;
  sixthFormGroup: UntypedFormGroup;

  initMapCampagne = true;

  dataJson: {};
  adresse = "";
  imageData: any;
  imageDataCampagne: any;
  nameFile = "";
  nameFileCampagne = "";
  textPub: string = "";
  range: number = 30;

  titre: string = "";
  longitude: number;
  latitude: number;
  nomPub: string = "";

  public static circle: any;
  public static LatLngmarker: any;
  public static range2: number = 30;

  markernew: any;
  position: any;

  timeDebutCampagne: any;
  timeFinCampagne: any;

  rangeCampagne: number = 30;
  public static circleCampagne: any;
  public static LatLngmarkerCampagne: any;
  public static range2Campagne: number = 30;
  markernewCampagne: any;
  positionCampagne: any;
  longitudeCampagne: number;
  latitudeCampagne: number;

  filtre: {
    Nom: string,
    Prenom: string,
    DateNaissance: Date,
    LieuNaissance: string,
    Nationalite: string,
    Telephone: string
  } = {
      Nom: "",
      Prenom: "",
      DateNaissance: new Date(),
      LieuNaissance: "",
      Nationalite: "",
      Telephone: ""
    };

  constructor(private http: HttpClient, private router: Router, private _formBuilder: UntypedFormBuilder, private dialog: MatDialog) {
    if (!LoginComponent.token) {
      this.router.navigate(['/']);
    }
  }

  


  ngOnInit() {
    this.initMapCampagne = true;

    this.firstFormGroup = this._formBuilder.group({
      nomCtrl: ['', Validators.required]
    });

    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['']
    });

    this.thirdFormGroup = this._formBuilder.group({
      file: new UntypedFormControl('', [Validators.required]),
      titleCtrl: ['', Validators.required],
      bodyCtrl: ['', Validators.required],
      fileSource: new UntypedFormControl('', [Validators.required])
    });
    this.fourthFormGroup = this._formBuilder.group({
      montantCtrl: [1, Validators.required],
      coutParVueCtrl: [1, Validators.required],
      nombreCtrl: [1],
      cappingCtrl: [1, Validators.required]
    });
    this.fithFormGroup = new UntypedFormGroup({
      debutCtrl: new UntypedFormControl('', [Validators.required]),
      finCtrl: new UntypedFormControl('', [Validators.required])
    });
    this.sixthFormGroup = this._formBuilder.group({
      filtreCtrl: ['']
    });

    this.findMe();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.longitude = position.coords.longitude;
        this.latitude = position.coords.latitude;

        //const myMap = L.map('map').setView([position.coords.latitude, position.coords.longitude], 19);
        //myMap.addControl(searchControl);

        //L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        //  attribution: 'Flytagger'
        //}).addTo(myMap);


        //const myIcon = L.icon({
        //  iconUrl: "../assets/images/pointeur2.svg",
        //  iconSize: [100, 100],
        //  iconAnchor: [50, 80],
        //  popupAnchor: [0, -72]
        //});


        //CreationCampagneComponent.circle = L.circle([position.coords.latitude, position.coords.longitude], { radius: CreationCampagneComponent.range2 });
        ////CreationCampagneComponent.circleCampagne = L.circle([position.coords.latitude, position.coords.longitude], { radius: CreationCampagneComponent.range2 });

        //CreationCampagneComponent.circle.addTo(myMap);

        //CreationCampagneComponent.LatLngmarker = L.marker([position.coords.latitude, position.coords.longitude], { icon: myIcon, draggable: true });
        //CreationCampagneComponent.LatLngmarker.bindPopup('déplacer le marqueur pour indiquer votre position').addTo(myMap).openPopup();

        //CreationCampagneComponent.LatLngmarker.on('mouseup', function (mouseEvent: any) {
        //  this.markernew = mouseEvent.latlng;
        //  this.position = this.markernew;

        //  this.latitude = this.position.lat;
        //  this.longitude = this.position.lng;

        //  const newLngmarker = this.setLatLng([this.latitude, this.longitude], { icon: myIcon, draggable: 'false' });
        //  newLngmarker.bindPopup('déplacer le marqueur pour indiquer votre position').update().openPopup();
        //});

        //CreationCampagneComponent.LatLngmarker.on('drag', function (e) {
        //  var newPos = new L.LatLng(e.latlng.lat, e.latlng.lng);
        //  console.log("e.LatLng", e.latlng);
        //  CreationCampagneComponent.circle.setLatLng(newPos);

        //});

        //myMap.on('geosearch/showlocation', function (e: any) {
        //  var newLatLng = new L.LatLng(parseFloat(e.location.y), parseFloat(e.location.x));

        //  CreationCampagneComponent.LatLngmarker.setLatLng(newLatLng);
        //  CreationCampagneComponent.circle.setLatLng(newLatLng);
        //});

      });

    } else {
      alert("Geolocation is not supported by this browser.");
    }

  }


  campagneMap() {

    //if (navigator.geolocation && this.initMapCampagne) {
    //  navigator.geolocation.getCurrentPosition((position) => {
    //    this.longitudeCampagne = position.coords.longitude;
    //    this.latitudeCampagne = position.coords.latitude;


    //    const myIconCampagne = L.icon({
    //      iconUrl: "../assets/images/pointeur2.svg",
    //      iconSize: [100, 100],
    //      iconAnchor: [50, 80],
    //      popupAnchor: [0, -72]
    //    });

    //    const myMapCampagne = LCampagne.map('mapCampagne').setView([position.coords.latitude, position.coords.longitude], 19);
    //    myMapCampagne.addControl(searchCampagneControl);

    //    LCampagne.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
    //      attribution: 'Flytagger'
    //    }).addTo(myMapCampagne);

    //    CreationCampagneComponent.circleCampagne = LCampagne.circle([position.coords.latitude, position.coords.longitude], { radius: CreationCampagneComponent.range2Campagne });
    //    CreationCampagneComponent.circleCampagne.addTo(myMapCampagne);

    //    CreationCampagneComponent.LatLngmarkerCampagne = LCampagne.marker([position.coords.latitude, position.coords.longitude], { icon: myIconCampagne, draggable: true });
    //    CreationCampagneComponent.LatLngmarkerCampagne.bindPopup('déplacer le marqueur pour indiquer votre position').addTo(myMapCampagne).openPopup();

    //    CreationCampagneComponent.LatLngmarkerCampagne.on('mouseup', function (mouseEvent: any) {
    //      this.markernewCampagne = mouseEvent.latlng;
    //      this.positionCampagne = this.markernewCampagne;

    //      this.latitudeCampagne = this.positionCampagne.lat;
    //      this.longitudeCampagne = this.positionCampagne.lng;

    //      const newLngmarker = this.setLatLng([this.latitudeCampagne, this.longitudeCampagne], { icon: myIconCampagne, draggable: 'false' });
    //      newLngmarker.bindPopup('déplacer le marqueur pour indiquer votre position').update().openPopup();
    //    });

    //    CreationCampagneComponent.LatLngmarkerCampagne.on('drag', function (e) {
    //      //latlng: LatLng {lat: 50.66739140400565, lng: 3.188420771507259}
    //      var newPos = new LCampagne.LatLng(e.latlng.lat, e.latlng.lng);
    //      console.log("e.LatLng", e.latlng);
    //      CreationCampagneComponent.circleCampagne.setLatLng(newPos);

    //    });

    //    myMapCampagne.on('geosearch/showlocation', function (e: any) {
    //      var newLatLng = new LCampagne.LatLng(parseFloat(e.location.y), parseFloat(e.location.x));

    //      CreationCampagneComponent.LatLngmarkerCampagne.setLatLng(newLatLng);
    //      CreationCampagneComponent.circleCampagne.setLatLng(newLatLng);
    //    });
    //  });
    //  this.initMapCampagne = false;
    //}


  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageData = reader.result;
        this.nameFile = event && event.target ? event.target.files[0].name : this.nameFile;
      };
    }
  }

  clearFile() {
    this.imageData = "";
    (<HTMLInputElement>document.getElementById("fileInput")).value = "";
  }

  //get f() {
  //  return this.thirdFormGroup.controls;
  //}

  onFileChangeCampagne(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];

      this.thirdFormGroup.patchValue({
        fileSource: file
      });
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageDataCampagne = reader.result;
        this.nameFileCampagne = event && event.target ? event.target.files[0].name : this.nameFileCampagne;
      };
    }
  }

  clearFileCampagne() {
    this.imageDataCampagne = "";
    (<HTMLInputElement>document.getElementById("fileInput")).value = "";
  }


  newRange() {
    CreationCampagneComponent.range2 = this.range;
    CreationCampagneComponent.circle.setRadius(this.range);
  }

  newRangeCampagne() {
    CreationCampagneComponent.range2Campagne = this.rangeCampagne;
    CreationCampagneComponent.circleCampagne.setRadius(this.rangeCampagne);
  }

  saveAdresse() {
    //this.adresse = searchCampagneControl.resultList.results[0].label;
  }

  onSubmit() {

    this.dialog.open(DialogComponent, {
      data: {
        title: "Enregistrement de la Publicité",
        message: 'Enregistrement en cours !!!',
        enableLoader: true,
        congrat: false
      }
    });

    var createdPub = {
      "Titre": this.titre,
      "Body": this.textPub,
      "ImageData2": this.imageData,
      "Distance": 3000, // this.range,
      "Nom": NavMenuComponent.userLog
    };

    this.dataJson = {
      "user": NavMenuComponent.usrConnected,
      "pub": createdPub,
      "filtre": this.filtre,
      "coordonnee": {
        "Longitude": 0, // CreationCampagneComponent.LatLngmarker.getLatLng().lng,
        "Latitude": 0, // CreationCampagneComponent.LatLngmarker.getLatLng().lat
      }
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });

    this.http.post(urlConfig.getUrl("pub"), this.dataJson, { headers: headers }).subscribe(data => {
      console.log(data);
      this.dialog.closeAll();

      this.dialog.open(DialogComponent, {
        data: {
          title: "Publicité enregistré",
          message: 'La publicité a bien été enregistré !!!',
          enableLoader: false,
          congrat: true
        }
      });

      setTimeout(() => { this.dialog.closeAll(); }, 3000);

    }, error => {
      console.log("error", error);
      this.dialog.closeAll();

      this.dialog.open(DialogComponent, {
        data: {
          title: "Erreur lors de l'enregistrement",
          message: 'Une erreur est survenue lors de l\'enregistrement !!!',
          enableLoader: false,
          congrat: false
        }
      });

      setTimeout(() => { this.dialog.closeAll(); }, 3000);

    });

  }

  findMe() {
    //if (navigator.geolocation) {
    //  navigator.geolocation.getCurrentPosition((position) => {
    //    this.longitude = position.coords.longitude;
    //    this.latitude = position.coords.latitude;
    //    this.longitudeCampagne = position.coords.longitude;
    //    this.latitudeCampagne = position.coords.latitude;

    //  });
    //} else {
    //  alert("Geolocation is not supported by this browser.");
    //}
  }

  saveFiltre() {
    console.log("filtre : ", this.filtre);
  }

  closeFiltre() {
    this.filtre = {
      Nom: "",
      Prenom: "",
      DateNaissance: new Date(),
      LieuNaissance: "",
      Nationalite: "",
      Telephone: ""
    };
  }

  calculeNombre() {

    if (this.fourthFormGroup.value['coutParVueCtrl'] == 0) {
      this.fourthFormGroup.patchValue({ "coutParVueCtrl": 1 })
    }

    var nb = this.fourthFormGroup.value['montantCtrl'] / this.fourthFormGroup.value['coutParVueCtrl'];
    this.fourthFormGroup.patchValue({ "nombreCtrl": nb });

  }

  sendCampagne() {

    let campagneGlobal = {
      nom: this.firstFormGroup.value['nomCtrl'],
      longitude: CreationCampagneComponent.LatLngmarkerCampagne.getLatLng().lng,
      latitude: CreationCampagneComponent.LatLngmarkerCampagne.getLatLng().lat,
      PubNavigation: {
        titre: this.thirdFormGroup.value['titleCtrl'],
        body: this.thirdFormGroup.value['bodyCtrl'],
        ImageData2: this.imageDataCampagne,
        Distance: this.rangeCampagne
      },
      montant: this.fourthFormGroup.value['montantCtrl'].toString(),
      coutParVue: this.fourthFormGroup.value['coutParVueCtrl'].toString(),
      nombreEnvoie: this.fourthFormGroup.value['nombreCtrl'].toString(),
      capping: this.fourthFormGroup.value['cappingCtrl'].toString(),
      dateDebut: new Date(this.fithFormGroup.value['debutCtrl']),
      dateFin: new Date(this.fithFormGroup.value['finCtrl']),
      heureDebut: this.timeDebutCampagne ? "" + this.timeDebutCampagne.hour + ":" + this.timeDebutCampagne.minute + ":" + this.timeDebutCampagne.second : null,
      heureFin: this.timeFinCampagne ? "" + this.timeFinCampagne.hour + ":" + this.timeFinCampagne.minute + ":" + this.timeFinCampagne.second : null,
      filtre: this.sixthFormGroup.value['filtreCtrl'],
      login: NavMenuComponent.userLog
    };


    this.dialog.open(DialogComponent, {
      data: {
        title: "Enregistrement de la Campagne",
        message: 'Enregistrement en cours !!!',
        enableLoader: true,
        congrat: false
      }
    });

    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': LoginComponent.token });

    this.http.post(urlConfig.getUrl("campagne"), campagneGlobal, { headers: headers }).subscribe((data: any) => {
      console.log(data);
      this.dialog.closeAll();

      if (data.success) {
        this.dialog.open(DialogComponent, {
          data: {
            title: "Campagne enregistré",
            message: 'La Campagne a bien été enregistré !!!',
            enableLoader: false,
            congrat: true
          }
        });
      }
      else {
        this.dialog.open(DialogComponent, {
          data: {
            title: "Erreur lors de l'enregistrement",
            message: 'Une erreur est survenue lors de l\'enregistrement !!!',
            enableLoader: false,
            congrat: false
          }
        });
      }


      setTimeout(() => { this.dialog.closeAll(); }, 3000);

    }, error => {
      console.log("error", error);
      this.dialog.closeAll();

      this.dialog.open(DialogComponent, {
        data: {
          title: "Erreur lors de l'enregistrement",
          message: 'Une erreur est survenue lors de l\'enregistrement !!!',
          enableLoader: false,
          congrat: false
        }
      });

      setTimeout(() => { this.dialog.closeAll(); }, 3000);

    });


  }

  calculeHeureMinute(heure) {
    return heure < 10 ? '0' + heure : heure
  }
}
