<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <!-- <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="logo"> -->
    <!--<span>Flytagger</span>-->
    <img src="../../assets/image/logoLogin.png" style="height: 2em;"/>
  </a>

  <p-button #menubutton (click)="layoutService.onMenuToggle(); onMenuToggle()" [icon]="isOutsideClicked ? 'pi pi-window-maximize' : 'pi pi-window-minimize'"></p-button>

  <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">

    <div class="flex flex-row justify-content-around align-items-center" style="width: 30em;">
      <p-dropdown [options]="lstEnseigne" [(ngModel)]="enseigneSelected" optionLabel="Nom" (onChange)="onChangeEnseigne()">

        <!-- Modèle pour l'affichage de l'élément sélectionné -->
        <ng-template pTemplate="selectedItem" let-selectedEns>
          <span>{{selectedEns.Nom}}</span>
        </ng-template>

        <!-- Modèle pour l'affichage des éléments de la liste -->
        <ng-template pTemplate="item" let-ens>
          <div class="flex align-items-center justify-content-center">
            <img *ngIf="item?.Image" [src]="item.Image" alt="Image de l'enseigne" />
            <div class="flex align-items-center flex-column">
              <span style="font-weight:900;">{{ens.Nom}}</span>
              <span>{{ens.Adresse}}</span>
              <span>{{ens.CodePostal}}</span>
              <span>{{ens.Ville}}</span>
            </div>
          </div>
        </ng-template>

      </p-dropdown>

      <div class="tooltipDiv-container">
        <i class="pi pi-qrcode" style="font-size: 2rem" (click)="toggleTooltip('qrcode')"></i>
        <div class="tooltipDiv" *ngIf="isTooltipVisible.qrcode">
          <span class="phraseQrCode">QR code de votre établissement</span>
          <!--<qr-code class="qrCode"
           #qrcodeElement
           (qrCodeURL)="onChangeURL($event)"
           [qrdata]="qrCodeEnseigne"
           [width]="250"
           [height]="250"
           [errorCorrectionLevel]="'M'"
           [imageSrc]="imageSrc"
           [imageHeight]="imageHeight"
           [imageWidth]="imageWidth"
           [colorDark]="'#000000'" [colorLight]="'#ffffff'" elementType="canvas"></qr-code>
  <button (click)="downloadQRCode()">Télécharger QR Code</button>-->

          <qrcode class="qrCode" (qrCodeURL)="onChangeURL($event)" [qrdata]="qrCodeEnseigne" [imageSrc]="imageSrc" [imageHeight]="54" [imageWidth]="40" [width]="256" [errorCorrectionLevel]="'M'" [colorDark]="'#000000'" [colorLight]="'#ffffff'" elementType="canvas"></qrcode>
          <p-button (click)="downloadQRCode()" icon="pi pi-save" label="Télécharger mon QR code"></p-button>
        </div>
      </div>

      <div class="tooltipDiv-container">
        <i class="pi pi-camera" style="font-size: 2rem" (click)="toggleTooltip('camera')" *ngIf="( enseigneSelected && enseigneSelected.IdContratsNavigation && enseigneSelected.IdContratsNavigation.AnimationClientele)"></i>
        <div class="tooltipDivCamera" *ngIf="isTooltipVisible.camera">
          <div *ngIf="availableDevices && !qrResultString" class="selectCamDiv">
            <select [(ngModel)]="idXselectedDevice" (change)="onDeviceSelectChange($event.target.value)">
              <option value="" [selected]="!selectedDevice">No Device</option>
              <option *ngFor="let device of availableDevices; let idx = index;" [value]="idx" [selected]="selectedDevice && device.deviceId === selectedDevice.deviceId">{{ device.label }}</option>
            </select>
          </div>

          <div class="divParentScanner" *ngIf="!qrResultString">
            <zxing-scanner #scanner class="test-class" [formats]="allowedFormats" (camerasFound)="camerasFoundHandler($event)" start="true" [(device)]="selectedDevice" (scanSuccess)="onCodeResult($event)"></zxing-scanner>
          </div>

          <section class="results" *ngIf="qrResultString">

            <div *ngIf="qrCodeREsultJson.type == 'carte'">

              <div class="divCarteValidation" *ngIf="type != 2 && qrCodeREsultJson.type != 'Lot' ">
                <fieldset class="scheduler-border">
                  <legend class="scheduler-border">Points à ajouter</legend>
                  <div class="donneeFielDiv">
                    <p-dropdown [options]="arrayNumber" [(ngModel)]="addPointFidelite" optionLabel="name"></p-dropdown>
                    <!--<mat-form-field appearance="outline">
                    <mat-select [(ngModel)]="addPointFidelite">
                      <mat-option style="color: black" *ngFor="let num of arrayNumber" [value]="num">{{num}}</mat-option>
                    </mat-select>
                  </mat-form-field>-->

                    <button pButton pRipple label="valider" class="p-button-success" (click)="validerPointFidelite()"></button>

                  </div>

                </fieldset>
              </div>

              <div class="divCarteValidation" *ngIf="type == 2 ">
                <fieldset class="scheduler-border">
                  <legend class="scheduler-border">Tampon à ajouter</legend>
                  <div class="donneeFielDiv">
                    Choix carte :
                    <p-dropdown [options]="lotFideliteDropDown" [(ngModel)]="choixLotFideliteDropDown" optionLabel="name"></p-dropdown>
                    <!--<mat-form-field appearance="outline">
                    <mat-select [(ngModel)]="choixLotFidelite">
                      <mat-option style="color: black" *ngFor="let l of lotFidelite" [value]="l">{{l.Lot}}</mat-option>
                    </mat-select>
                  </mat-form-field>-->
                    Nombre de tampon :
                    <p-dropdown [options]="arrayNumber" [(ngModel)]="addPointFidelite" optionLabel="name"></p-dropdown>
                    <!--<mat-form-field appearance="outline">
                    <mat-select [(ngModel)]="addPointFidelite">
                      <mat-option style="color: black" *ngFor="let num of arrayNumber" [value]="num">{{num}}</mat-option>
                    </mat-select>
                  </mat-form-field>-->

                    <button pButton pRipple label="valider" class="p-button-success" (click)="validerPointFidelite()"></button>

                  </div>

                </fieldset>
              </div>


            </div>

            <div *ngIf="qrCodeREsultJson.type == 'Lot'">

              <div class="divLotValidation">
                <div>

                  <fieldset class="scheduler-border">
                    <legend class="scheduler-border">Valider gain</legend>

                    <div>


                      <button pButton pRipple label="valider" class="p-button-success" (click)="validerCode()"></button>

                    </div>

                  </fieldset>

                </div>
              </div>

            </div>

            <div style="margin: 1em;" *ngIf="!qrResultString">
              <button mat-raised-button color="primary" (click)="clearResult()">Annuler</button>
            </div>
          </section>
        </div>
      </div>

    </div>

  </div>
</div>


<style>
  



</style>
