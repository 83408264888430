import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';

@Component({
  selector: 'app-statistique',
  templateUrl: './statistique.component.html',
  styleUrls: ['./statistique.component.scss']
})
export class StatistiqueComponent implements OnInit {
  urlGetPub: string = "https://207.180.246.59:57429/api/Command/GetPubByLogin";
  urlGetStat: string = "https://207.180.246.59:57429/api/Command/getStatFromPub/";

  //urlGetPub: string = "https://localhost:5005/api/Command/GetPubByLogin/";
  //urlGetStat: string = "https://localhost:5005/api/Command/getStatFromPub/";

  lstPub: any;
  pubSelected: any;
  stat: any;
  selectedIndex = 0;

  prixTotPub = 0;
  prixOuverturePub = 0;
  prixVisionnagePub = 0;
  pourcentageOuverturePub = 0;
  pourcentageVisionnagePub = 0;

  constructor(private http: HttpClient) {

  }
  
  ngOnInit() {
    this.http.post(this.urlGetPub, { login: NavMenuComponent.userLog }).subscribe((response: any) => {
      this.lstPub = response.data;
      this.pubSelected = response.data[0];
      this.getStat();
    });
  }

  onSelectPub(pubSelect, index) {
    if (index != this.selectedIndex) {
      this.pubSelected = pubSelect;
      this.selectedIndex = index;
      this.getStat();
    }
  }

  getStat() {
    this.http.post(this.urlGetStat, this.pubSelected).subscribe((response: any) => {
      this.stat = response.data;
      this.prixTotPub = this.stat.nbEnvoie * 1;
      this.prixOuverturePub = Math.round(100 *(this.prixTotPub / this.stat.nbNotifcation)) /100;
      this.prixVisionnagePub = Math.round(100 *(this.prixTotPub / this.stat.nbVue)) / 100;
      this.pourcentageOuverturePub = Math.round(100 *((this.stat.nbNotifcation / this.stat.nbEnvoie))  ) ;
      this.pourcentageVisionnagePub = Math.round(100 * ((this.stat.nbVue / this.stat.nbNotifcation)));

      console.log("statistique", this.stat);
    });
  }
}
