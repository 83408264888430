import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-avertissement',
  templateUrl: './dialog-avertissement.component.html',
  styleUrls: ['./dialog-avertissement.component.scss']
})
export class DialogAvertissementComponent implements OnInit {
  title = "";
  message : any;
  message2: any;
  message3: any;
  message4: any;
  displayImage = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<DialogAvertissementComponent>) {
    if (data) {
      this.title = data.title || this.title;
      this.message = data.message || this.message;
      this.message2 = data.message2 || this.message2;
      this.message3 = data.message3 || this.message3;
      this.message4 = data.message4 || this.message4;
      this.displayImage = data.displayImage || this.displayImage;

    }
  }

  ngOnInit(): void {
  }


  onClick(item): void {
    this.dialogRef.close({ valide: item });
  }


}
