<!--<div *ngIf="contrat.communication" class="flex flex-row align-items-center justify-content-around ">

  <div class="d-flex flex-row align-items-center justify-content-around" style="margin-top: 1em; height: 30em; width: 45%">

    <fieldset class="scheduler-border">

      <div class="d-flex flex-column align-items-center justify-content-around msgDivInput">
        <div class="inputDiv">
          <div>Définissez ici votre message promotionnel ou de communication qui sera envoyé instantanément </div>
        </div>
        <div class="inputDiv">
          <div class="infoEnseigneDiv">
            <img [src]="communication.Image" style="max-height: 10em; border-radius: 10px; border: 1px solid black; " />
            <div>
              <input class="file-field" #imageInput type="file" name="file" id="file" (change)="onFileChange($event)" accept=".jpg,.jpeg,.png,.bmp" />
              <label for="file" class="file-label">
                <figure>
                  <i class="fa fa-upload"></i>
                </figure>
                <span>Choisir une image&hellip;</span>
              </label>
            </div>
          </div>

          <form class="example-form">
            <mat-form-field class="example-form-field" appearance="fill">
              <mat-label>Titre</mat-label>
              <input matInput #titre [(ngModel)]="communication.Titre" name="titre" style="color: black" maxlength="100">
              <mat-hint align="start"><strong>nombre de caractères </strong> </mat-hint>
              <mat-hint align="end">{{communication && communication.Titre.length}} / 100</mat-hint>
            </mat-form-field>
            <mat-form-field class="example-form-field" appearance="fill">
              <mat-label>Description</mat-label>
              <input matInput #description [(ngModel)]="communication.Description" name="description" style="color: black" maxlength="300">
              <mat-hint align="start"><strong>nombre de caractères </strong> </mat-hint>
              <mat-hint align="end">{{communication && communication.Description.length}} / 300</mat-hint>
            </mat-form-field>

          </form>
        </div>
      </div>

    </fieldset>

  </div>
  <div>

    <app-smartphone-display [image]="communication.Image" [titre]="communication.Titre" [body]="communication.Description" [backgroundSize]="backgroundSize" [titreColor]="colorTitre" [bodyColor]="colorBody"></app-smartphone-display>

  </div>

  <div class="divBouttonPubComm">
    <button mat-raised-button color="primary" (click)="sendCommunication()">Envoyer</button>
  </div>


</div>-->


<div class="card flex justify-content-center" *ngIf="contrat.communication">
  <div style="margin-bottom: 1em;">
    <p-fileUpload #fileUpload
      mode="basic"
      name="demo[]" 
      (uploadHandler)="onImageUpload($event)"
      customUpload="true" auto="true" multiple="false" chooseLabel="Choisir un fichier (image, pdf ou vidéo)">
    </p-fileUpload>
  </div>

  <div class="card" style="height: 55vh; ">
    <pintura-editor #editor class="inline-editor"
                    [options]="editorOptions"
                    [imageCropAspectRatio]="0" [stickers]="stickers"
                    (process)="handleInlineProcess($event)" *ngIf="!pdfEnable"></pintura-editor>

      <div class="flex flex-row justify-content-between align-items-start" style="height: 100%; width: 100%;" *ngIf="pdfEnable">
        <ngx-extended-pdf-viewer [src]="pdfFile" [useBrowserLocale]="true" ></ngx-extended-pdf-viewer>

        <button class="boutonExport"  (click)="export()" >Terminé</button>
      </div>
    

  </div>

</div>

<div *ngIf="!contrat.communication" class="notGame">
  <div class="titleNotGame">Soyez visibles auprès de vos clients.</div>
  <div class="textNotGame">
    L’envoi de messages sur l’activité de votre commerce, permet une communication aux plus proches de vos clients par SMS, email, notification.
    Votre choix d’envoyer un message ciblé apportera du trafic client les jours de faible affluence.
  </div>

  <div class="textNotGame">
    Ce service fait partie de l’offre communication.
  </div>

  <div class="textNotGame">
    Pour bénéficier de cette offre ou visualiser une démo contacter nous au 05.61.446.449 ou à {{'contact@flytagger.com'}}
  </div>
</div>

<p-dialog header="Communication" [(visible)]="showDialog" [style]="{width: '10vw'}">
  <i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
  <p>
    Envoie en cours !!!
  </p>
</p-dialog>