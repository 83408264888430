<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content style="text-align: center;">

  <p *ngIf="message">{{message}}</p>
  <div *ngIf="displayImage" class="imgCat">
    <div class="textImg">
      <h4>Fidélité par points</h4>
      <p>Votre client pourra cumuler autant de points qu’il souhaite et les utiliser pour l’un des lots que vous aurez défini.</p>
      <img src="assets/image/parPoint.jpg" />
    </div>
    <div class="textImg">
      <h4>Fidélité par tampons</h4>
      <p>
        Votre client pourra cumuler des tampons sur les différentes cartes que vous aurez défini.
        Dès qu'une carte sera complète, il obtiendra le lot correspondant.
      </p>
      <img src="assets/image/parCategorie.jpg" />
    </div>
  </div>
  
  <p *ngIf="message2">{{message2}}</p>
  <h2 *ngIf="message3">{{message3}}</h2>
  <p *ngIf="message4" style="color: red">{{message4}}</p>

  <div *ngIf="!displayImage">
    <button mat-raised-button color="warn" (click)="onClick(false)">Annuler</button>
    <button mat-raised-button color="primary" (click)="onClick(true)" style="margin-left: 1em;">Ok</button>
  </div>

  <div *ngIf="displayImage" class="boutonImageOk">
    <button mat-raised-button color="primary" (click)="onClick(false)" >Ok</button>
  </div>
  

</div>
