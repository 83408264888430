<div class="parentDivVendeur">
  <div class="parentDiv">
    <div style="text-align: justify; width: 70%; margin: auto; margin-bottom: 2em;">
      Vous avez la possibilité de définir deux codes secrets essentiels qui seront utilisés dans l'interaction avec vos clients sur notre application dédiée.

      Code de Validation de Gain :
      Ce code est utilisé pour confirmer et valider les récompenses ou gains que vos clients peuvent obtenir. Lorsqu'un client souhaite réclamer une récompense, vous entrerez ce code pour authentifier et approuver la transaction.

      Code pour l'Ajout de Points de Fidélité :
      Ce deuxième code est destiné à l'ajout de points de fidélité sur le compte de vos clients. À chaque achat, vous utiliserez ce code pour créditer le compte fidélité du client en conséquence, renforçant ainsi leur engagement envers votre commerce.

      Ces codes sont conçus pour sécuriser les transactions et assurer que seules les actions autorisées soient effectuées sur l'application. Veillez à choisir des codes qui soient à la fois sûrs et faciles à mémoriser pour vous et votre équipe. Pour la sécurité de votre commerce, nous vous recommandons de changer ces codes régulièrement.

      En personnalisant ces codes, vous facilitez et sécurisez les transactions avec vos clients, tout en leur offrant une expérience fluide et agréable.
    </div>

    <div class="card flex flex-row justify-content-around align-items-center" style="width: 100%; margin-top:1em;">

      <div class="infoEnseigneDiv">
        <mat-label>Code d'attribution des points de fidélité </mat-label>
        <input type="text" pInputText [(ngModel)]="enseigneModif.Code" maxlength="6">
      </div>
      <div class="infoEnseigneDiv">
        <mat-label>Code de validation des gains </mat-label>
        <input type="text" pInputText [(ngModel)]="enseigneModif.CodeValiationGain" maxlength="6">
      </div>

    </div>

    <div class="flex flex-row justify-content-center align-items-center">
      <button pButton type="button" label="Enregistrer" color="primary" (click)="submit()" style="width: 10em; text-align: center; background-color: darkblue; color:white;"></button>
    </div>

  </div>

</div>


