<div style="background-color:#242424; text-align:initial; margin-top: 50px; border-top-left-radius: 40px;border-top-right-radius: 40px; width:91em; height:47em;">

  <!--<h1 style="margin-left:20px; padding-top:20px;"><span style="color:#019FC2">STAT</span>ISTIQUE</h1>-->
  <div style="width:100%; text-align:center">
    <img src="../../assets/images/cloche.png" style="position: absolute;top: 5em;width: 130px;" />
  </div>

  <div style="display: flex;margin-top: 1em;flex-direction: row; margin-left: 1em;">
    <div style="display: flex;flex-direction: column;">
      <div class="divListePub">
        <h2>Liste des publicités envoyée</h2>
        <ul class="globalListePub">
          <li *ngFor="let pub of lstPub; let index = index;" [class]="index == selectedIndex ? 'listeTitlePub active' : 'listeTitlePub'" (click)="onSelectPub(pub,index)">
            <span>{{index}}</span>{{pub.titre}}
          </li>
        </ul>
      </div>
      
    </div>

    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-evenly; align-items: center; width: 100%;">
      <div class="circleStat" >
        <ul>
          <li> <span>1</span> Pub envoyé à &nbsp; {{stat.nbEnvoie | number | removeComma}} &nbsp; {{stat.nbEnvoie > 1 ? 'utilisateurs': 'utilisateur'}}</li>
          <li> <span>2</span> {{stat.nbNotifcation | number | removeComma}} &nbsp; {{stat.nbNotifcation > 1 ? 'utilisateurs': 'utilisateur'}}, on ouvert la publicité </li>
          <li> <span>3</span> {{stat.nbVue | number | removeComma}} &nbsp; {{stat.nbVue > 1 ? 'utilisateurs': 'utilisateur'}}, on regardé la publicité</li>
          <li style=" border: 1px solid red; border-radius: 10px; text-align: center;">  Rémunération d'une publicité : 1 &euro;</li>
        </ul>
      </div>
      <div class="circleStat">
        <ul >
          <li> <span>A</span> Coups totale de la publicité :  &nbsp; {{prixTotPub | number | removeComma}} &euro; </li>
          <li> <span>B</span> Coups d'ouverture de la publicité : &nbsp; {{prixOuverturePub | number | removeComma}} &euro; <p>efficacité : &nbsp; {{pourcentageOuverturePub}} &nbsp; % des envoies</p>  </li>
          <li> <span>C</span> Coups de visionnage de la publicité : &nbsp; {{prixVisionnagePub | number | removeComma}} &euro; <p>efficacité : &nbsp; {{ pourcentageVisionnagePub }} &nbsp; % des ouvertures</p>   </li>
        </ul>
      </div>
    </div>
    
  </div>
</div>
